import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import SendWhite from "../assets/icons/send-white.svg";
import SendGradient from "../assets/icons/send-gradient.svg";
import LinkWhite from "../assets/icons/link-white.svg";
import LinkGradient from "../assets/icons/link-gradient.svg";
import HelpWhite from "../assets/icons/help-white.svg";
import HelpGradient from "../assets/icons/help-gradient.svg";
import AIWhite from "../assets/icons/ai-white.svg";
import AIGradient from "../assets/icons/ai-gradient.svg";
import LoginWhite from "../assets/icons/log-white.svg";
import LoginGradient from "../assets/icons/log-gradient.svg";
import WalletWhite from "../assets/icons/wallet-white.svg";
import WalletGradient from "../assets/icons/wallet-gradient.svg";

const FeatureCard = ({ iconWhite, iconGradient, title, description }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const iconSrc = useBreakpointValue({
    base: iconGradient,
    md: isHovered ? iconGradient : iconWhite,
  });

  const titleColor = useBreakpointValue({
    base: "white",
    md: isHovered ? "white" : "white",
  });

  const baseBg = useBreakpointValue({
    base: `
      linear-gradient(135deg, #333 0%, #333 20%, transparent 51%),
      linear-gradient(315deg, #333 0%, #333 20%, transparent 51%),
      radial-gradient(circle at top right, rgba(146, 86, 231, 0.9), transparent 70%),
      radial-gradient(circle at bottom left, rgba(146, 86, 231, 0.9), transparent 70%)
    `,
    md: "#222",
  });

  return (
    <Box
      position="relative"
      borderRadius="16px"
      overflow="hidden"
      bg={baseBg}
      p={0.5}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      _hover={
        isMobile
          ? {}
          : {
              "&::before": {
                transform: "scale(1.5)",
                opacity: 1,
              },
            }
      }
      sx={
        isMobile
          ? {}
          : {
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(146, 86, 231, 0.9), transparent 60%)",
                transition: "transform 0.3s, opacity 0.3s",
                pointerEvents: "none",
                opacity: 0,
              },
            }
      }
      onMouseMove={
        isMobile
          ? undefined
          : (e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              e.currentTarget.style.setProperty(
                "--mouse-x",
                `${e.clientX - rect.left}px`
              );
              e.currentTarget.style.setProperty(
                "--mouse-y",
                `${e.clientY - rect.top}px`
              );
            }
      }
      display="flex"
      flexDirection="column"
      minHeight="230px"
    >
      <Box
        position="relative"
        borderRadius="15px"
        overflow="hidden"
        bg="#111"
        p={6}
        flex="1"
        display="flex"
        flexDirection="column"
      >
        <VStack align="start" spacing={3} flex="1">
          <Box position="relative">
            <Image
              src={iconSrc}
              boxSize={{ base: "25px", sm: "30px", lg: "38px" }}
              alt={title}
            />
          </Box>
          <Heading
            size="md"
            color={titleColor}
            sx={{ WebkitFontSmoothing: "antialiased" }}
          >
            {title}
          </Heading>
          <Text
            fontSize={{ base: "14px", sm: "16px" }}
            color="rgba(255, 255, 255, 0.5)"
            sx={{ WebkitFontSmoothing: "antialiased" }}
          >
            {description}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

const StyledText = ({
  children,
  color = "white",
  sizes = ["24px", "30px", "24px", "28px", "30px"],
  mb,
}) => (
  <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
    {children}
  </Text>
);

function MainFeatures() {
  const features = [
    {
      iconWhite: SendWhite,
      iconGradient: SendGradient,
      title: "Kolay Gönderim",
      description:
        "Telefon numarası, e-posta, kullanıcı adı ile anında para gönderin.",
    },
    {
      iconWhite: WalletWhite,
      iconGradient: WalletGradient,
      title: "Varlıklarının Tek Sahibi Ol",
      description:
        "%100 self-custody (bireysel) cüzdan. MPC teknolojisi ile güvende tutulur.",
    },
    {
      iconWhite: LoginWhite,
      iconGradient: LoginGradient,
      title: "Karmaşık Değil, Basit",
      description:
        "Özel anahtarları ve kelimeleri bilmek zorunda değilsiniz. E-posta ve telefon numaranız cüzdan açmak ve güvende tutmak için yeterli.",
    },
    {
      iconWhite: AIWhite,
      iconGradient: AIGradient,
      title: "Finansal Co-Pilot",
      description:
        "NorbiAI ile finansal adımlarınızı atarken yalnız değilsiniz.",
    },
    {
      iconWhite: HelpWhite,
      iconGradient: HelpGradient,
      title: "Merkeziyetsiz Finans Parmaklarının Ucunda",
      description:
        "Merkeziyetsiz finans dünyasına giriş yapmak için en kolay yol. Dahili merkeziyetsiz cüzdan ile önde gelen blockchain tabanlı varlıklara ve uygulamalara direkt erişim sağlayın.",
    },
    {
      iconWhite: LinkWhite,
      iconGradient: LinkGradient,
      title: "Geleneksel ve Merkeziyetsiz Finans Arasında Güvenli Köprü",
      description:
        "İster merkezi borsalardan isterseniz merkeziyetsiz borsalardan alım-satım yapabilirsiniz.",
    },
  ];

  return (
    <Box
      bg="transparent"
      py={4}
      color="white"
      mt={8}
      px={{ base: "5%", lg: 32 }}
    >
      <VStack spacing={4} align="stretch" maxW="1200px" mx="auto">
        <Box textAlign="center">
          <StyledText color="#9256E7" fontWeight="bold" mb={4}>
            ÖZELLİKLER
          </StyledText>
          <Text
            fontSize={{ base: "24px", sm: "50px", md: "40px", lg: "50px" }}
            fontWeight="bold"
            sx={{ WebkitFontSmoothing: "antialiased" }}
            color={"rgb(236, 236, 236)"}
          >
            Yenilikçi, Şeffaf, Hızlı ve Güvenilir
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

export default MainFeatures;
