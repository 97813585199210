import React from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const PolicySection = ({ title, children }) => (
  <Box mt={6}>
    <Heading as="h2" size="md" mb={4}>
      {title}
    </Heading>
    {children}
  </Box>
);

const CerezPolitikasi = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        YENKİDO BİLİŞİM HİZMETLERİ TİCARET ANONİM ŞİRKETİ
      </Heading>

      <Heading as="h2" mb={8} textAlign="center">
        ÇEREZ POLİTİKASI
      </Heading>

      <Text>Son Güncelleme: 05.08.2024</Text>

      <Text mt={4}>
        Halaskargazi Cad. No: 38-66E İç Kapı No: 215, Nişantaşı Lotus, 34371 Şişli/İstanbul adresinde mukim
        Yenkido Bilişim Hizmetleri Ticaret Anonim Şirketi ("Norbi", "Şirketimiz" veya "biz") olarak, internet
        sitelerimizin (https://norbi.app, https://web.norbi.app, https://polyverse.life) ve mobil uygulamamızın
        (bundan sonra hep birlikte "Platform") düzgün çalışmasını sağlamak ve ziyaretçilerimizin ("Kullanıcı(lar)"
        veya "siz") deneyimlerini geliştirmek ve kişiselleştirmek amacıyla çerezler, pikseller, gifler, web
        işaretçileri (web beacons) gibi bazı teknolojilerden ("Çerez(ler)") faydalanmaktayız.
      </Text>

      <Text mt={4}>
        İşbu Çerez Politikası ("Politika"), 6698 sayılı Kişisel Verilerin Korunması Kanunu'na ("Kanun") uygun olarak
        Platform'da kullandığımız Çerezler, amaçlarımız, Çerezler'in nasıl kontrol edileceği, Çerezler yoluyla
        toplanan kişisel verileriniz ve haklarınız hakkında size bilgi vermek amacıyla hazırlanmıştır.
      </Text>

      <Text mt={4}>
        Platform üzerinden paylaştığınız ve/veya topladığımız kişisel verilerinize ilişkin olarak, Norbi veri
        sorumlusu olarak hareket etmektedir. Konuya ilişkin daha fazla bilgiye sahip olmak için Aydınlatma
        Metnimizi inceleyebilirsiniz.
      </Text>

      <Text mt={4}>
        Norbi olarak Platform'da kullandığımız Çerezler'i kullanmaktan vazgeçebilir, bunların türlerini veya
        fonksiyonlarını değiştirebilir veya Platform'a yeni Çerezler ekleyebiliriz. Dolayısıyla, işbu Politika
        hükümlerini dilediğimiz zaman değiştirme hakkını saklı tutarız. Güncel Politika üzerinde gerçekleştirilmiş
        olan her türlü değişiklik Platform'da veya herhangi bir kamuya açık mecrada yayınlanmakla birlikte
        yürürlük kazanacaktır. Bu değişiklikleri gözden geçirmek için zaman zaman bu sayfayı ziyaret etmenizi
        tavsiye ederiz. Son güncelleme tarihini metnin başında bulabilirsiniz.
      </Text>

      <PolicySection title="1. ÇEREZLER, BENZER TEKNOLOJİLER VE TOPLAMA YÖNTEMLERİ">
        <Text>
          Kişisel verilerinizi, Çerezler ve benzer teknolojiler kullanarak elektronik olarak otomatik yöntemlerle
          toplayabilmekteyiz.
        </Text>
        <Text mt={4}>
          Çerezler (cookies), Platform üzerinden bilgi toplayabilmek, tercihlerinizle ilgili bir özet oluşturmak
          amacıyla kullandığımız ve tarayıcınız aracılığıyla bir web sunucusu tarafından bilgisayarınıza veya mobil
          cihazınıza yüklenen genellikle harf ve rakamlardan oluşan küçük veri dosyalarıdır.
        </Text>
        <Text mt={4}>
          Web işaretçileri (web beacons), Platformumuz, hizmetlerimiz, uygulamalarımız, mesajlaşmalarımız ve
          araçlarımıza dahil edilebilen, genellikle çerezler ile birlikte çalışan, kullanıcılarımız ile kullanıcı
          davranışlarını tespit etmeye yarayan ve "piksel etiketleri" veya "clear GIFler" olarak da bilinen ufak kısa
          grafik imgelerdir.
        </Text>
        <Text mt={4}>
          Benzer teknolojiler, yerel paylaşımlı nesne veya yerel depolama kullanarak tarayıcı veya cihazınızda bilgi
          saklayan flash çerezler, HTML 5 çerezler ve diğer internet uygulamaları, yazılım yöntemleri gibi
          teknolojilerdir. Bu teknolojiler, tüm tarayıcılarınız üzerinden çalışabilir. Bazı hallerde, bunları tümüyle
          tarayıcınız aracılığıyla kontrol edemeyebilir ve bu tür durumlarda doğrudan doğruya yüklenen
          uygulamalarınız veya cihazınız aracılığıyla kontrol sağlamanız gerekebilir.
        </Text>
        <Text mt={4}>
          İşbu Politika'da "Çerezler" ifadesi; tarayıcınızda veya cihazınızda veri saklamak için kullandığımız ve işbu
          Politika'da belirtilen şekilde bilgi toplayan veya sizi teşhis etmemize yardımcı olan çerezler, web
          işaretçileri, benzer teknolojiler dahil tüm teknolojileri ifade etmek üzere kullanılabilmektedir.
        </Text>
        <Text mt={4}>
          Web siteleri, Çerezler'i tarayıcınız ile sunucu arasında bağlantı kurulduğunda sizi tanımak için kullanır.
          Çerezler, bir web sitesinin ziyaretçileri tarafından daha kolay kullanılabilmesi için tasarlanır. Çerezler;
          adınız-soyadınız, adresiniz, şifreniz gibi kişisel verilerinizi ve hassas nitelikteki kişisel verilerinizi toplamaz
          ve kaydetmez.
        </Text>
        <Text mt={4}>
          Çerezler konusunda daha detaylı bilgi için www.aboutcookies.org ve www.allaboutcookies.org adreslerini
          ziyaret edebilirsiniz. Yalnızca Çerezler ve diğer benzer teknolojiler aracılığıyla kullanılabilir olan birtakım
          Platform özellikleri, hizmetleri ve uygulamaları sunmaktayız. Çerezler ve diğer benzer teknolojileri,
          tarayıcınızın, yüklediğiniz uygulamanın veya cihazınızın müsaade etmesi halinde, her zaman engelleyebilir,
          silebilir veya devre dışı bırakabilirsiniz. Ancak, Çerezler ve diğer benzeri teknolojilerin kullanımını devre
          dışı bırakmanız halinde, birtakım Platform özelliklerinden, hizmetlerinden ve uygulamalardan
          faydalanamayabilirsiniz. Ayrıca, tarayıcı oturumunuz esnasında şifrenizi daha sık girmeniz gerekebilir. Bu
          teknolojileri nasıl engelleyeceğiniz, sileceğiniz veya devre dışı bırakacağınız hakkında daha ayrıntılı bilgi
          için, lütfen tarayıcı ve cihaz ayarlarınızı gözden geçiriniz.
        </Text>
      </PolicySection>

      <PolicySection title="2. ÇEREZLERİN KULLANILMA AMAÇLARI VE HUKUKİ SEBEPLERİ">
        <Text>
          Norbi olarak, Platform'da çeşitli amaçlarla Çerezler kullanmakta ve bu Çerezler vasıtasıyla kişisel
          verilerinizi işlemekteyiz. Bu amaçlar başlıca şunlardır:
        </Text>
        <List mt={4} spacing={2}>
          <ListItem>• Platform'un güvenli bir şekilde çalışabilmesi,</ListItem>
          <ListItem>
            • Kullanıcılar'ın Platform üzerinde hareket edebilmesi ve sağlanan hizmet ve özelliklerden
            faydalanabilmesi,
          </ListItem>
          <ListItem>
            • Platform'un işlevli hale getirilmesi ve Platform'un Kullanıcılar'a uygun şekilde kişiselleştirilmesi,
          </ListItem>
          <ListItem>
            • Kullanıcılar'ın Platform kullanımlarının analiz edilmesi,
          </ListItem>
          <ListItem>
            • Platform'un performansının arttırılması ve yeni özellikler sunulması,
          </ListItem>
          <ListItem>
            • Kullanıcılar'ın ilgi alanlarına ve/veya profillerine göre hedefli reklamlar gösterilmesi ve doğrudan
            ve sair pazarlama faaliyetlerinin yürütülmesi.
          </ListItem>
        </List>
        <Text mt={4}>
          Çerezler vasıtasıyla Platform'un size çalışır biçimde sunulabilmesi adına Kanun'un 5. maddesi kapsamında
          belirtilen "bir sözleşmenin kurulması veya ifası ile doğrudan doğruya ilgili olması kaydıyla sözleşmenin
          taraflarına ait kişisel verilerin işlenmesinin gerekli olması, ilgili kişinin temel hak ve özgürlüklerine zarar
          vermemek kaydıyla veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması" hukuki
          sebeplerine dayalı olarak kişisel verileriniz işlenmektedir.
        </Text>
        <Text mt={4}>
          Platform'da kullandığımız, kesinlikle gerekli Çerezler haricindeki Çerezler için açık rızanız alınmaktadır.
          Verdiğiniz açık rızayı dilediğiniz zaman geri alabilirsiniz.
        </Text>
      </PolicySection>

      <PolicySection title="3. KULLANILAN ÇEREZLERİN TÜRLERİ">
        <Text>
          Platform'da kullanılan Çerezler'in türleri aşağıda belirtilmiştir.
        </Text>
        <Text mt={4}>
          Platform'da, kullanım süresine göre kalıcı çerezler kullanılmaktadır. Kalıcı çerezler ise tarayıcınız
          kapatıldığında kendiliğinden silinmemekte ve hard diskinizde uzun süre boyunca kalabilmektedir.
          Saklanma süreleri birkaç günden birkaç yıla kadar değişebilir.
        </Text>
        <Text mt={4}>
          Platform'da taraflarına göre birinci taraf ve üçüncü taraf çerezler kullanılmaktadır. Birinci taraf çerezler,
          doğrudan Platform tarafından cihazınıza yerleştirilmektedir. Üçüncü taraf çerezlerse diğer üçüncü taraf
          internet siteleri tarafından cihazınıza yerleştirilmektedir.
        </Text>
        <Text mt={4}>
          Platform'da kullanım amaçlarına göre aşağıdaki çerezler kullanılmaktadır:
        </Text>

        <Heading as="h3" size="sm" mt={6}>
          3.1. Kesinlikle Gerekli Çerezler
        </Heading>
        <Text mt={2}>
          Kesinlikle gerekli çerezler, Platform'un düzgün ve güvenli bir şekilde çalışabilmesi, Kullanıcılar'ın Platform
          üzerinde hareket edebilmesi ve talep ettikleri hizmetlerden ve özelliklerden faydalanabilmesi için
          kesinlikle gerekli olan çerezlerdir. Çerezin sadece, iletişimin sağlanması amacıyla kullanılması veya çerez
          kullanımını, Kullanıcı'nın hizmet almak için açıkça talep ettiği durumlarda söz konusu çerez kesinlikle
          gerekli kabul edilmektedir. Bu çerezler ayrıca tabi olduğumuz ilgili mevzuattan kaynaklanan yasal
          yükümlülüklerimizi yerine getirebilmemiz için gereklidir. Kullanıcılar'ın, bu çerezleri tarayıcıları üzerinden
          engellemesi halinde Platform'un bazı kısımları çalışmayabilecektir.
        </Text>

        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th>Çerezin Adı veya Basit Açıklaması</Th>
              <Th>Kullanım Amacı</Th>
              <Th>Birinci Taraf / Üçüncü Taraf</Th>
              <Th>Saklama Süresi</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>__cf_bm</Td>
              <Td>İnsanlar ile botlar arasında ayrım yapar</Td>
              <Td>Üçüncü Taraf Çerezler</Td>
              <Td>30 dakika</Td>
            </Tr>
            <Tr>
              <Td>_ga</Td>
              <Td>Kullanıcılar'ı ayırt etmek için kullanılır.</Td>
              <Td>Birinci Taraf Çerezler</Td>
              <Td>13 ay</Td>
            </Tr>
            <Tr>
              <Td>_ga_&lt;container id&gt;</Td>
              <Td>Oturum durumunu korumak için kullanılır.</Td>
              <Td>Birinci Taraf Çerezler</Td>
              <Td>13 ay</Td>
            </Tr>
            <Tr>
              <Td>token</Td>
              <Td>
                Kullanıcı sisteme giriş yapmışsa, Kullanıcı oturumunu saklamak
                için kullanılır.
              </Td>
              <Td>Birinci Taraf Çerezler</Td>
              <Td>Süresiz</Td>
            </Tr>
          </Tbody>
        </Table>

        <Text mt={4}>
          Kesinlikle gerekli çerezler yoluyla toplanan kişisel verileriniz Kanun'un 5/2 (c) maddesinde belirtilen bir
          sözleşmenin kurulması veya ifası ile doğrudan doğruya ilgili olması kaydıyla sözleşmenin taraflarına ait
          kişisel verilerin işlenmesinin gerekli olması ve Kanun'un 5/2 (f) maddesinde belirtilen ilgili kişinin temel
          hak ve özgürlüklerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaatleri için veri
          işlenmesinin zorunlu olması hukuki sebeplerine dayalı olarak işlenmektedir.
        </Text>

        <Heading as="h3" size="sm" mt={6}>
          3.2. İşlevsellik ve Tercih Çerezleri
        </Heading>
        <Text mt={2}>
          İşlevsellik ve tercih çerezleri, Kullanıcılar'ın tercihlerine ilişkin bilgilerin toplanması, Platform'un
          Kullanıcılar'a uygun şekilde kişiselleştirilmesi, işlevselliğin sağlanması, ve tercihlerin hatırlanması
          amaçlarıyla kullanılır. Böylece, Kullanıcı tarafından yapılan seçimleri tanıyarak Kullanıcı'nın Platform'a
          ziyaretini kolaylaştırır. Ayrıca bu çerezler, Kullanıcılar'a gelişmiş web özellikleri de sağlar. Bu çerezler
          tarafımızca veya sayfalarımıza hizmetlerini eklediğimiz üçüncü taraf sağlayıcılar tarafından yerleştirilebilir.
        </Text>

        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th>Çerezin Adı veya Basit Açıklaması</Th>
              <Th>Kullanım Amacı</Th>
              <Th>Birinci Taraf / Üçüncü Taraf</Th>
              <Th>Saklama Süresi</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>chakra-ui-color-mode</Td>
              <Td>Websitesi renk tercihini belirler</Td>
              <Td>Birinci Taraf Çerezler</Td>
              <Td>Süresiz</Td>
            </Tr>
          </Tbody>
        </Table>

        <Text mt={4}>
          İşlevsellik ve tercih çerezleri yoluyla toplanan kişisel verileriniz Kanun'un 5/1 maddesinde belirtilen açık
          rızanızın alınması hukuki sebebine dayalı olarak işlenmektedir.
        </Text>
      </PolicySection>

      <PolicySection title="4. AKTARIM">
        <Text>
          Çerezler yoluyla toplanan kişisel verileriniz, işbu Politika'nın 2. maddesinde açıklanan amaçlarla Kanun'un
          8. ve 9. maddeleri kapsamında kişisel verilerin aktarılmasına ilişkin kurallara uygun olarak ve Kanun'un 5.
          maddesinde düzenlenen veri işleme şartları kapsamında; yurt içinde ve yurt dışında bulunan
          tedarikçilerimize, iş ortaklarımıza, yetkili kamu kurum ve kuruluşlarına ve hukuken yetkili kişilere
          aktarılabilecektir.
        </Text>
      </PolicySection>

      <PolicySection title="5. ÇEREZLER NASIL KONTROL EDİLİR/DEVRE DIŞI BIRAKILIR?">
        <Text>
          Çerez tercihlerinizi, tarayıcı ayarlarınızı değiştirerek değiştirebilirsiniz. Tarayıcınızın sunmuş olduğu
          imkanlara göre, Çerezler'in kullanılmasını engelleyebilir, Çerez kullanılmadan önce uyarı alabilir veya
          sadece bazı Çerezler'i devre dışı bırakabilir ya da silebilirsiniz. Çerezlerle alakalı tercihlerinizin,
          Platform'a erişim sağladığınız her bir cihaz (tarayıcı ayarları) özelinde ayrı olarak ayarlanması
          gerekmektedir.
        </Text>
        <Text mt={4}>
          Çerezler'i nasıl etkinleştireceğiniz, devre dışı bırakacağınız ya da kaldıracağınıza ilişkin bilgiler, internet
          tarayıcısı sağlayıcısının sitesindeki Yardım ekranında yer almaktadır.
        </Text>
        <List mt={4} spacing={2}>
          <ListItem>
            <Link
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr"
              isExternal
            >
              • Google Chrome:
              https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://support.mozilla.org/tr/kb/%C3%87erezleri%20engellemek"
              isExternal
            >
              • Mozilla:
              https://support.mozilla.org/tr/kb/%C3%87erezleri%20engellemek
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies"
              isExternal
            >
              • Internet Explorer:
              https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://support.apple.com/kb/PH5042?locale=en-GB"
              isExternal
            >
              • Safari: https://support.apple.com/kb/PH5042?locale=en-GB
            </Link>
          </ListItem>
          <ListItem>
            <Link href="http://www.opera.com/help/tutorials/" isExternal>
              • Opera: http://www.opera.com/help/tutorials/
            </Link>
          </ListItem>
        </List>
        <Text mt={4}>
          Bazı Çerezler'i devre dışı bırakmanız halinde Platform'un çeşitli fonksiyonlarının çalışmayabileceğini
          hatırlatmak isteriz.
        </Text>
      </PolicySection>

      <PolicySection title="6. İLGİLİ KİŞİ HAKLARINIZ">
        <Text>
          İlgili kişi olarak, Kanun'un 11. maddesi uyarınca aşağıdaki haklara sahipsiniz:
        </Text>
        <List mt={4} spacing={2}>
          <ListItem>
            • Kişisel verilerinizin işlenip işlenmediğini öğrenme,
          </ListItem>
          <ListItem>
            • Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
          </ListItem>
          <ListItem>
            • Kişisel verilerin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
          </ListItem>
          <ListItem>
            • Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
            üçüncü kişileri bilme,
          </ListItem>
          <ListItem>
            • Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
            bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
            kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
            isteme,
          </ListItem>
          <ListItem>
            • Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini
            gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini
            isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
            isteme,
          </ListItem>
          <ListItem>
            • İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
            edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz
            etme,
          </ListItem>
          <ListItem>
            • Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğramanız halinde bu zararın giderilmesini talep etme.
          </ListItem>
        </List>
        <Text mt={4}>
          Bu haklarınıza ilişkin taleplerinizi, kimlik tespitinizin yapılmasını sağlayıcı belgeleri de eklemek suretiyle,
          [https://norbi.app/veri-basvuru-formu] bağlantısındaki Veri Sahibi Başvuru Formu'nu doldurarak Veri
          Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'de yer verilen usul ve esaslar çerçevesinde
          aşağıda bilgilerimize yer verdiğimiz iletişim yöntemlerinden biri üzerinden bize iletebilirsiniz. Başvurunuz
          ücretsiz olarak yanıtlanacaktır. Ancak talep edilen işlemin ayrıca bir maliyet gerektirmesi halinde, Kişisel
          Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücretler talep edilecektir.
        </Text>
        <Text mt={4}>
          Başvurularınız, talebin niteliğine göre mümkün olan en kısa sürede ve en geç otuz (30) gün içinde yazılı
          veya elektronik ortamdan ücretsiz cevaplanacaktır. Ancak, talep edilen işlemin ayrıca bir maliyet
          gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret talep
          edilecektir. Başvurunun Şirketimiz hatasından kaynaklanması hâlinde alınan ücret tarafınıza iade
          edilecektir. Talebinizin reddedilmesi halinde, başvurunuz gerekçeli olarak yanıtlanacaktır.
        </Text>

        <Heading as="h3" size="sm" mt={6}>
          İLETİŞİM BİLGİLERİ
        </Heading>
        <Text mt={2}>E-posta Adresi: destek@norbiapp.com</Text>
        <Text>Kayıtlı E-posta Adresi (KEP): yenkidobilisim@hs01.kep.tr</Text>
        <Text>Adres: Halaskargazi Cad. No: 38-66E İç Kapı No: 215, Nişantaşı Lotus, 34371 Şişli/İstanbul</Text>
      </PolicySection>
    </Box>
  );
};

export default CerezPolitikasi;