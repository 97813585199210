import React from "react";
import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

const PolicySection = ({ title, children }) => (
  <Box mt={6}>
    <Heading as="h2" size="md" mb={4}>
      {title}
    </Heading>
    {children}
  </Box>
);

const KendiAdinaBaskasiHesabinaIslem = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        KENDİ ADINA, BAŞKASI HESABINA İŞLEM YAPANLARA İLİŞKİN DUYURU
      </Heading>

      <PolicySection>
        <Text>
          5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun'un ("Kanun") 27'nci maddesine dayanılarak hazırlanan Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik ("Yönetmelik") 09.01.2008 tarihli Resmî Gazete'de yayımlanarak, 01.04.2008 tarihi itibarıyla yürürlüğe girmiştir.
        </Text>
        <Text mt={4}>
          Yönetmelik'e göre; sürekli iş ilişkilerinde (Yönetmelik kapsamında, Yükümlü ile müşteri arasında hesap açılması, kredi veya kredi kartı verilmesi, kiralık kasa, finansman, faktoring, finansal kiralama, hayat sigortası veya bireysel emeklilik gibi hizmetler nedeniyle kurulan, niteliği itibarıyla devamlılık unsuru taşıyan iş ilişkisini ifade etmektedir) Yükümlüler, tutar gözetmeksizin kimliğe ilişkin bilgileri almak ve bu bilgilerin doğruluğunu teyit etmek suretiyle müşterilerin ve müşteri adına hareket edenlerin kimliğinin tespit edilmesi zorunludur.
        </Text>
        <Text mt={4}>
          Bunun dışında, sürekli iş ilişkisi niteliği arz etmemekle birlikte;
        </Text>
        <UnorderedList mt={2} spacing={2}>
          <ListItem>
            İşlem tutarı ya da birbiriyle bağlantılı birden fazla işlemin toplam tutarı yüzseksenbeş bin TL veya üzerinde olduğunda,
          </ListItem>
          <ListItem>
            Elektronik transferlerde işlem tutarı ya da birbiriyle bağlantılı birden fazla işlemin toplam tutarı onbeşbin TL veya üzerinde olduğunda,
          </ListItem>
          <ListItem>
            Şüpheli işlem bildirimini gerektiren durumlarda tutar gözetmeksizin,
          </ListItem>
          <ListItem>
            Daha önce elde edilen müşteri kimlik bilgilerinin yeterliliği ve doğruluğu konusunda şüphe olduğunda tutar gözetmeksizin, kimliğe ilişkin bilgilerin alınması ve bu bilgilerin doğruluğunu teyit etmek suretiyle müşterilerinin ve müşterileri adına veya hesabına hareket edenlerin kimliğinin tespit edilmesi ve işlemin gerçek faydalanıcısının ortaya çıkarılması için gerekli tedbirlerin alınması zorunludur.
          </ListItem>
        </UnorderedList>

        <Text mt={4}>
          İşlem talep eden kişiler, eğer işlemi bir başkası hesabına yapıyorlarsa, bunu işlemi yapmadan önce beyan etmek zorundadır. Bu beyan üzerine, işlemi talep edenin kimliği ve yetki durumu ile hesabına hareket edilen kişinin kimliği Yönetmelik'in 6 ila 14'üncü maddeleri arasındaki hükümlere göre tespit edilir.
        </Text>

        <Text mt={4}>
          Ticaret siciline kayıtlı tüzel kişilerle kurulan sürekli iş ilişkilerinde, tüzel kişiliğin yüzde yirmibeşini aşan hisseye sahip gerçek ve tüzel kişi ortakların kimliği Yönetmelik'in 6 ve 7'nci maddelerine göre tespit edilir.
        </Text>
        <Text mt={4}>
          Kanun'un 15'inci maddesi uyarınca, kimlik tespiti gereken işlemlerde, kendi adına ve fakat başkası hesabına hareket eden kimse, bu işlemleri yapmadan önce kimin hesabına hareket ettiğini yazılı olarak bildirmediği takdirde, altı aydan bir yıla kadar hapis veya beşbin güne kadar adli para cezasıyla cezalandırılır.
        </Text>
        <Text mt={4}>
          İşbu duyuru Yönetmelik'in 17'inci maddesi uyarınca yapılmaktadır.
        </Text>
      </PolicySection>
    </Box>
  );
};

export default KendiAdinaBaskasiHesabinaIslem;