import { Box, Heading, Text } from "@chakra-ui/react";

const AboutUs = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Hakkımızda
      </Heading>

      <Text mt={4}>
        Norbi, geleneksel ve merkeziyetsiz finans arasındaki boşluğu köprülemek
        için tasarlanmış kapsamlı kripto varlık alım-satım ve cüzdan hizmetidir. Standart bir
        kripto cüzdanının yeteneklerinin ötesine geçen ve üst düzey
        kripto/itibari ve kripto/kripto ticaret deneyimi sunan Norbi,
        kullanıcıların özel ihtiyaçlarını karşılayacak şekilde
        özelleştirilebilen esnek bir mimari sunarak kusursuz bir kullanıcı
        deneyimi sağlamayı hedefler. Web3'e adaptasyonu basitleştirme misyonuyla
        Norbi, kripto varlıkların doğasında olan zorlukları ortadan kaldırarak
        web3 erişimini herkes için ulaşılabilir hale getirir.
      </Text>
    </Box>
  );
};

export default AboutUs;
