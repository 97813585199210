import React from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Input,
  Checkbox,
  VStack,
  Link,
} from "@chakra-ui/react";

const PolicySection = ({ title, children }) => (
  <Box mt={6}>
    <Heading as="h2" size="md" mb={4}>
      {title}
    </Heading>
    {children}
  </Box>
);

const AMLKYCPolicy = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        YENKİDO BİLİŞİM HİZMETLERİ TİCARET ANONİM ŞİRKETİ
      </Heading>
      <Heading as="h2" mb={8} textAlign="center">
        Kara Para Aklamayı Önleme ve Müşterini Tanı Politikası
      </Heading>

      <Table variant="simple" mb={8}>
        <Tbody>
          <Tr>
            <Td fontWeight="bold">Doküman sahibi:</Td>
            <Td>Remzi Binar</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Yayınlanma tarihi:</Td>
            <Td>24.01.2024</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">İnceleme tarihi:</Td>
            <Td>24.01.2024</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Versiyon:</Td>
            <Td>1</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Onaylayan:</Td>
            <Td>Remzi Binar</Td>
          </Tr>
        </Tbody>
      </Table>

      <Table variant="simple" mb={8}>
        <Thead>
          <Tr>
            <Th colSpan={4} textAlign="center">
              Versiyon Geçmişi
            </Th>
          </Tr>
          <Tr>
            <Th>Versiyon</Th>
            <Th>Tarih</Th>
            <Th>Değişikliklerin Özeti</Th>
            <Th>Düzenleyen</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>1</Td>
            <Td>24.01.2024</Td>
            <Td>Asıl</Td>
            <Td>Remzi Binar</Td>
          </Tr>
        </Tbody>
      </Table>

      <PolicySection title="1. GİRİŞ">
        <Text>
          Bu Kara Para Aklamayı Önleme ve Müşterini Tanı Politikası
          ("Politika"), gerekli tüm önlemleri almamızı ve kara para aklamayı ya
          da terörizmin finansmanını veya suç faaliyetlerini kolaylaştıran
          herhangi bir faaliyeti aktif olarak önlememizi sağlamayı
          amaçlamaktadır.
        </Text>
        <Text mt={4}>
          Yenkido Bilişim Hizmetleri Ticaret Anonim Şirketi (Buradan itibaren
          “biz”, “bizler”, “Şirket”, “Norbi” olarak anılacaktır.) ve çalışanları
          (“Çalışan(lar)”), üçüncü taraf bağlantıları, müşterileri ve
          kullanıcıları (“Müşteri(ler) veya Kullanıcı(lar)”) her zaman
          dolandırıcılıkla mücadele, yolsuzlukla mücadele ve mali suçları
          azaltmak için gerekli önlemleri almak da dahil olmak üzere Kara Para
          Aklanmasının Önlenmesi’ne (Bundan sonra “Anti-Money Laundering”
          ifadesinin kısaltması olan “AML” ifadesi kullanılacaktır.) ilişkin en
          yüksek standartlara riayet etmektedir.
        </Text>
        <Text mt={4}>
          Norbi olarak, AML ile ilgili yürürlükteki tüm mevzuat ve düzenlemelere
          tam anlamıyla uymaktayız ve geniş bir finansal işlem alanıyla ilgili
          olarak AML düzenlemelerin ve mevzuatın bu kapsamda bizlere ciddi bir
          sorumluluk yüklediğinin bilincindeyiz.
        </Text>
        <Text mt={4}>
          İşbu Politika’ya ilişkin herhangi bir sorunuz veya talebiniz olması
          halinde, Hukuk Departmanı ile iletişime geçebilirsiniz.
        </Text>
      </PolicySection>

      <PolicySection title="2. HUKUKİ DAYANAK">
        <Text>
          Norbi, Türkiye Cumhuriyeti yasalarına tam anlamıyla riayet eden bir
          kripto varlık hizmet sağlayıcıdır. Norbi, ticari faaliyetlerini,
          mevzuattan kaynaklanan yükümlülüklerini yerine getirecek şekilde
          şeffaf ve açık olarak yürütmektedir.
        </Text>
        <Text mt={4}>
          Norbi, bir kripto varlık hizmet sağlayıcı olarak işbu Politika’da yer
          verilen hususlara ve mevzuatın öngördüğü gerekliliklere tabidir. İşbu
          Politika, 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında
          Kanun, 6415 sayılı Terörizmin Finansmanının Önlenmesi Hakkında Kanun
          ve Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesine
          Dair Tedbirler Hakkında Yönetmelik ve Hazine ve Maliye Bakanlığı Mali
          Suçları Araştırma Kurulu (“MASAK”) Başkanlığı tarafından 18.04.2022
          tarihinde yayınlanan “Şüpheli İşlem Bildirim Rehberi (Kripto Varlık
          Hizmet Sağlayıcılar)” adlı Rehber (“Rehber”) başta olmak üzere
          yürürlükteki düzenlemelere uygun olarak oluşturulmuştur.
        </Text>
      </PolicySection>

      <PolicySection title="3. POLİTİKA'NIN AMACI">
        <Text>
          İşbu Politika’nın amacı, Norbi’nin: (i) Türk mevzuatında AML’e ilişkin
          öngörülen yükümlülük ve gereksinimler kapsamında uyumluluğunu
          sağlamak, (ii) suç gelirlerini aklama veya terörizmin finansmanı, mali
          yükümlülüklerin ihlali ve mali suçlar için suiistimal edilmesini
          önlemek, (iii) kendisinin ya da Müşterileri’nin mali suçlardan ötürü
          mağdur olmasını önlemek, (iv) yüksek riskli işler üstlenmemesini temin
          etmek ve (v) muhtemel ya da şüphelenilen suç gelirlerini aklama
          faaliyetini, terörizm finansmanı faaliyetini, mali yükümlülük
          ihlallerini ve mali suçları tespit etmesini sağlamaktır.
        </Text>
        <Text mt={4}>
          Bu durum ayrıca bize ve kullanıcılarımıza yönelen herhangi bir riski
          azaltmak için gerekli sistemlere ve denetim mekanizmalarına sahip
          olmamızı da kapsamaktadır. İşbu Politika aynı zamanda, tüm
          Çalışanlarımızın suç gelirlerinin aklanmasının önlenmesine ilişkin
          tedbirlerimizi ve kurallara uygun davranılmaması halinde hangi
          sonuçların doğabileceğine ilişkin kendi yükümlülüklerini bilmesini ve
          anlamasını sağlamayı da amaçlamaktadır.
        </Text>
        <Text mt={4}>
          Norbi, Çalışanlarının ve kullanıcılarının suç gelirlerinin aklanması
          faaliyetlerine maruz kalmamaları için elinden gelen azami çabayı sarf
          etmekte ve mevzuatta belirtilen kurallara ve rehberlere uygun hareket
          etmektedir. Bu kapsamda Norbi, suç gelirlerinin aklanması faaliyeti
          yahut sair türden bir mali suç kapsamında olduğundan şüphe duyduğu
          ve/veya tespit ettiği tüm faaliyetleri, mevzuata uygun düştüğü ölçüde
          ilgili kurumlara bildirecektir.
        </Text>
      </PolicySection>

      <PolicySection title="4. KAPSAM">
        <Text>
          İşbu Politika, belirsiz süreli, belirli süreli ve alt işveren olarak
          istihdam edilenler de dahil olmak üzere tüm Çalışanları, herhangi bir
          üçüncü taraf temsilcisini ya da alt yüklenicisini, gönüllüleri,
          stajyerleri ile Norbi ve grup şirketleri (bağlı şirketleri ve ana
          şirket) ile ilişkili kuruluşları bağlayıcı niteliktedir. İşbu Politika
          ayrıca, sözleşmesel ilişkiler kapsamında işbu Politika’nın konusuna
          giren iş ve işlemler için Norbi’nin ilişkili olduğu üçüncü taraflara
          da uygulanacaktır. İşbu Politika, Türkiye’deki AML düzenlemeleri ve
          ilgili mevzuatlar ve Norbi’nin politikaları dikkate alınarak
          oluşturulmuştur.
        </Text>
      </PolicySection>

      <PolicySection title="5. TEMEL KAVRAMLAR">
        <Heading as="h3" size="sm" mt={4}>
          5.1. Suç Gelirlerinin Aklanması (Kara Para Aklama) ve Terörizmin
          Finansmanı Nedir?
        </Heading>
        <Text mt={2}>
          a. Suç gelirlerinin aklanması (genel ve yaygın kullanımda “kara para
          aklama” olarak da bilinir), suçtan elde edilen gelirin yasadışı
          kaynağını gizlemek amacıyla finansal sistem aracılığıyla işlendiği,
          yani aklandığı süreçtir. Suç gelirinin aklanması, temel olarak şu
          halleri kapsamaktadır:
        </Text>
        <List mt={2} ml={8} spacing={2}>
          <ListItem>
            1. gelir elde edilen ve gizlenen suçlar (Örneğin; vergi kaçakçılığı,
            dolandırıcılık, hırsızlık, örgütlü suç, uyuşturucu kaçakçılığı,
            yolsuzluk, vb.);
          </ListItem>
          <ListItem>
            2. suç gelirlerinin gizlenmesi, aktarılması, meşru işlemlere konu
            edilmesi ya da dönüştürülmesi faaliyeti; ve
          </ListItem>
          <ListItem>
            3. kişinin, elde edilen gelirin bir suç geliri olduğunu bildiği ya
            da makul olarak bilmesi gerektiği haller.
          </ListItem>
        </List>
        <Text mt={4}>
          b. Terörün finansmanı suçu, bir terör eyleminde veya ölüm ya da
          bedensel yaralanmaya neden olmayı amaçlayan herhangi bir eylemde
          kullanılacağı niyeti veya bilgisi ile maddi kaynak sağlanmasını,
          toplanmasını veya bu kaynaktan faydalanılmasını içermektedir. Ayrıca,
          terör örgütü yararına kullanılması niyetiyle veya kullanılacağını
          bilerek maddi kaynak oluşturma, toplama veya bu kaynaktan faydalanma
          faaliyetleri de bu kapsamda sayılmaktadır.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          5.2. Mali Yaptırım Nedir?
        </Heading>
        <Text mt={2}>
          Mali yaptırımlar, uluslararası hukukun, insan haklarının veya
          politikaların, hukukun üstünlüğüne ya da demokratik ilkelere riayet
          etmemek suretiyle gerçekleştirilen ihlaller gibi faaliyetlerde veya bu
          yönde benimsenen politikalarda pozitif yönde bir değişiklik meydana
          getirmeyi amaçlayan diplomatik veya ekonomik nitelikte araçlardır.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          5.3. İstisnasız Uygulama
        </Heading>
        <List mt={2} spacing={2}>
          <ListItem>
            a. İşbu Politika'ya uyumluluk, her bir Çalışan için bir
            zorunluluktur.
          </ListItem>
          <ListItem>
            b. Remzi Binar, suç gelirlerinin aklanması ile mücadele süreçlerini
            tüm yönleri ile denetlemek üzere Şüpheli İşlem Raporlama Görevlisi
            ("Raporlama Görevlisi") olarak atanmıştır.
          </ListItem>
          <ListItem>
            c. Her bir Kullanıcı, işbu Politika'da yer alan hususlara tabidir.
          </ListItem>
          <ListItem>
            d. Herhangi bir Kullanıcı işbu Politika'nın ihlal edildiğini tespit
            ederse, bu durumu derhal Raporlama Görevlisi'ne bildirmelidir.
          </ListItem>
        </List>

        <Heading as="h3" size="sm" mt={4}>
          5.4. Veri Güvenliği
        </Heading>
        <Text mt={2}>
          Suç gelirlerinin aklanması, terörizmin finansmanı veya mali
          yaptırımların meydana gelmesi riskini azaltmak adına birtakım idari
          süreçler ve tedbirler planlanırken, kişisel verilerin korunmasına
          ilişkin tüm ilgili kişilerin hakları ve özgürlükleri gözetilmektedir.
          Bu kapsamda planlanan tüm idari süreçler ve tedbirler, 6698 sayılı
          Kişisel Verilerin Korunması Kanunu ve uygulanabilir tüm mevzuat ile
          uyumludur.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          5.5. Uyumsuzluk Halinde Yaptırımlar
        </Heading>
        <List mt={2} spacing={2}>
          <ListItem>
            a. AML düzenlemelerine aykırılık sonucu uygulanabilecek olan
            yaptırımlar hem kurumsal hem de bireyseldir: Kendi yükümlülüklerine
            riayet etmeyen bir şirket, uyarı, kınama, para cezası ve hukuka
            aykırılığı giderme gibi bir dizi yaptırım ile karşı karşıya
            kalabilir. AML düzenlemelerinin ihlali hallerinde öngörülen para
            cezaları, katı ve caydırıcı olacak şekilde belirlenmiştir. Bireylere
            yönelik yaptırımlar ise para cezalarını ve/veya hapis cezalarını
            içermektedir.
          </ListItem>
          <ListItem>
            b. Ayrıca, AML düzenlemelerinin ihlali faaliyetinin bir kez kamuya
            açık hale gelmesi, Norbi’nin itibarının azımsanamayacak ve telafi
            edilemeyecek şekilde zarar görmesine sebep olabilir.
          </ListItem>
          <ListItem>
            c. Denetleyici kurumlar, yetkili makamlarının denetim planına
            dayanarak, kendi takdirleri ile suç gelirlerinin aklanması ve/veya
            terörizmin finansmanının önlenmesine ilişkin mevzuatta belirtilen
            tedbirlerin uygulanmasına ilişkin bir denetim gerçekleştirme hakkına
            sahiptir.
          </ListItem>
          <ListItem>
            d. Denetim makamları, olası hukuka aykırılıklara ilişkin durumun
            kayıt altına alındığı bir raporun veya bu yönde herhangi bir verinin
            kendilerine ulaşması üzerine kendi takdirleri doğrultusunda olası
            hukuka aykırılıkların araştırılması ve açığa çıkartılması adına
            soruşturma başlatabilir.
          </ListItem>
        </List>
      </PolicySection>

      <PolicySection title="6. MÜŞTERİNİN TANINMASI VE DEĞERLENDİRME PROSEDÜRLERİ">
        <Text>
          Norbi, kullanıcı kimlik tespiti ve değerlendirme süreçleri
          aracılığıyla mali suçların ve kara para aklanmasının önlenmesini
          amaçlayan Müşterinin Tanınması (“KYC”) politikasının kuralları ile
          burada belirtildiği üzere uyumlu ve bağlıdır.
        </Text>
        <Text mt={4}>
          Tüm yeni Müşteriler/Kullanıcılar, 09.01.2008 tarihli Resmî Gazete’de
          yayımlanarak 01.04.2008 tarihinde yürürlüğe giren Suç Gelirlerinin
          Aklanmasının ve Terörün Finansmanının Önlenmesine Dair Tedbirler
          Hakkında Yönetmelik doğrultusunda, hesap açmadan önce aşağıda
          belirtildiği üzere prosedürel değerlendirme süreçlerini
          tamamlayacaktır. Kimlik tespitinin yapılamadığı veya iş ilişkisinin
          amacı hakkında yeterli bilgi edinilemediği durumlarda; iş ilişkisi
          tesis edilemez ve talep edilen işlem gerçekleştirilemez. Daha önce
          elde edilen Müşteri kimlik bilgilerinin yeterliliği ve doğruluğu
          konusunda şüphe duyulması nedeniyle yapılması gereken kimlik tespit ve
          teyidinin yapılamadığı durumlarda iş ilişkisi sona erdirilmeli ve
          belirtilen bu hususların şüpheli işlem olup olmadığı da ayrıca
          değerlendirilmelidir.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          6.1. Birincil Doğrulama
        </Heading>
        <Text mt={2}>
          a. Gerçek Kişiler: Norbi’ye ait internet siteleri, mobil uygulamalar
          veya yazılım uygulamaları (mevcut ve gelecekte kullanıma sunulacak
          olanlar da dahil) üzerinden veyahut Şirket’in sağladığı diğer
          çevrimiçi hizmetler (birlikte “Platform” olarak anılacaktır) üzerinden
          Norbi kripto varlık cüzdanına veya Norbi kripto varlık işlem
          platformuna kaydolmuş kullanıcıdır (“Kullanıcı”). Bu tür bir
          Kullanıcı, bir yasal zorunluluk olarak Norbi’ye, Kimliğini ve Adresini
          belirten kişisel belgelerini (Kimlik Tespiti, Adres Tespiti) ibraz
          etmekle yükümlüdür. Her yeni kişinin ya da işletmenin doğrulayıcı
          belgeleri, bir Yeni Müşteri Başvurusu olarak alınır ve kaydedilir.
        </Text>
        <Text mt={4}>
          Gerçek kişilerde kimlik tespiti, Suç Gelirlerinin Aklanması ve Terörün
          Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik, 5 No’lu
          Mali Suçları Araştırma Kurulu Genel Tebliği ve MASAK tarafından
          yayımlanan Kripto Varlık Hizmet Sağlayıcıları Rehberi uyarınca,
          aşağıdaki prosedür takip edilerek gerçekleştirilecektir:
        </Text>
        <List mt={2} spacing={2}>
          <ListItem>
            i. Gerçek kişilerin kimlik tespitinde; adı, soyadı, doğum tarihi,
            doğum yeri, uyruğu, Türk vatandaşları için T.C. kimlik numarası,
            yabancı uyruklular için yabancı kimlik numarası, cüzdan seri
            numarası, cüzdan numarası alınması zorunludur. Bu kimliğe ilişkin
            bilgiler, İçişleri Bakanlığı Nüfus ve Vatandaşlık İşleri Genel
            Müdürlüğü veri tabanı kullanılarak sorgulanması yoluyla teyit
            edilecektir.
          </ListItem>
          <ListItem>
            ii. Ayrıca;
            <List ml={8} mt={2} spacing={2}>
              <ListItem>
                • Türk vatandaşları için: T.C. nüfus cüzdanı, T.C. sürücü
                belgesi veya pasaport ile üzerinde T.C. kimlik numarası bulunan
                ve özel kanunlarında resmi kimlik hükmünde olduğu açıkça
                belirtilen kimlik belgeleri;
              </ListItem>
              <ListItem>
                • Türk vatandaşı olmayanlar için: pasaport, ikamet belgesi veya
                İçişleri Bakanlığınca uygun görülen kimlik belgelerinin
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            iii. Ayrıca, Türk vatandaşları için anne adı ve baba adı da
            istenebilecektir. Türk ve yabancı uyruklular için varsa telefon
            numarası, faks numarası ve e-posta adresi de alınabilir.
          </ListItem>
          <ListItem>
            iv. Son olarak, gerçek kişi kullanıcıların adres bilgileri
            istenebilecektir. Beyan edilen adresin doğruluğu, (i) yerleşim yeri
            belgesi, ilgili adına düzenlenmiş elektrik, su, doğalgaz, telefon
            gibi abonelik gerektiren bir hizmete ilişkin olan ve işlem
            tarihinden önceki üç ay içinde düzenlenmiş fatura veya (ii) MASAK
            tarafından uygun görülen diğer belge ve yöntemlerle teyit
            edilebilecektir. Teyide esas belgelerin asıllarının veya noterce
            onaylanmış suretlerinin ibrazı sonrası okunabilir fotokopisi veya
            elektronik görüntüsü alınır yahut belgeye ait ayırt edici bilgiler
            kaydedilir.
          </ListItem>
          <ListItem>
            v. Kişinin kendi fotoğrafını çekme (selfie) sürecinin bir parçası
            olarak, kullanıcıdan yüzünü ekranda yaklaşık 30 santimetre bir oval
            içinde konumlandırması ve ardından biraz daha yakına hareket etmesi
            istenir. Belirtilen doğrulama Sum and Substance Ltd (UK) şirketinden
            alınan hizmet yardımı ile yapılır (Ayrıntılar için lütfen işbu
            Politika’nın 10’uncu maddesine bakınız).
          </ListItem>
        </List>

        <Heading as="h3" size="sm" mt={4}>
          6.2. İkincil Doğrulama
        </Heading>
        <Text mt={2}>
          Norbi, tüm işlemlerdeki güvenlik kontrollerinin azami derecede
          ciddiyetle yapılmasını temin eder. Norbi, mevzuat kapsamında
          yasaklanan herhangi bir yasa dışı ya da hukuka aykırı alım-satım
          işleminden kaçınmak adına, Platformda gerçekleşen ve meblağı
          $10.000.000,00 (on milyon ABD doları) veya daha fazla olan tüm
          işlemlerde ilgili gerçek kişi kullanıcıların “fon/malvarlığı
          kaynağını” sunmalarını talep etmektedir.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          6.3. Değerlendirme Kontrolleri
        </Heading>
        <List mt={2} spacing={2}>
          <ListItem>
            a. Değerlendirme kontrolünün ve devam eden durum takibinin ne denli
            önemli olduğunu bildiğimizden ötürü; Norbi olarak, hesabı
            oluşturmadan/kabul etmeden önce geçmişe ilişkin kontroller
            yürütüyoruz ve buna ilişkin detayları, değerlendirme ve suç
            gelirlerinin aklanmasının önlenmesine yönelik kontrollerinin
            yapıldığını kanıt olarak göstermek amacıyla saklıyoruz.
          </ListItem>
          <ListItem>
            b. Yasal düzenlemelere ve gerekliliklere göre değerlendirme
            kontrolleri gerçekleştirilmektedir. Kullanıcılar/Müşteriler ile
            ilgili her türlü ortamdaki, yükümlülüklerine ve işlemlerine ilişkin
            tüm belgeler, hesaplar ve işlemlere dair tüm kayıtlar düzenleme
            tarihinden, defter ve kayıtlar ise son kayıt tarihinden; kimlik
            tespitine ilişkin belge ve kayıtlar son işlem tarihinden itibaren 8
            (sekiz) yıl süre ile saklanır. Bu kayıtlar istenmesi halinde
            yetkililere ibraz edilecektir. Kimlik tespitine ilişkin belgelerin
            muhafaza süresinin başlangıç tarihi, Müşteri’nin Norbi nezdindeki
            hesabının kapatıldığı tarihtir.
          </ListItem>
          <ListItem>
            Değerlendirme süreçleri yürütülürken; 5549 sayılı Suç Gelirlerinin
            Aklanmasının Önlenmesi Hakkında Kanun, 6415 sayılı Terörizmin
            finansmanının Önlenmesi Hakkında Kanun ve 6698 sayılı Kişisel
            Verilerin Korunması Kanunu başta olmak üzere yürürlükteki tüm
            mevzuata uyumlu faaliyetler yürütülmektedir. Belirtilen konuda
            kontroller Defy Dijital Yazılım Hizmetleri A.Ş. şirketinden alınan
            hizmet yardımı ile yapılır (Ayrıntılar için lütfen işbu Politika’nın
            10’uncu maddesine bakınız).
          </ListItem>
        </List>

        <Heading as="h3" size="sm" mt={4}>
          6.4. Gözetim ve Kontroller
        </Heading>
        <List mt={2} spacing={2}>
          <ListItem>
            a. Raporlama Görevlisi, Değerlendirme Kontrolleri’nin ve suç
            gelirlerinin aklanmasının önlenmesine yönelik tedbirlerin gereği
            gibi tesis edilmesinden ve amaca uygun olmalarından sorumludur.
            Düzenli denetimler; değerlendirme formları, şirket kontrolleri ve
            süre gelen gözetleme faaliyetleri üzerinden yapılmaktadır.
          </ListItem>
          <ListItem>
            b. Norbi’nin görevlendirdiği ekip, işbu maddede belirtilen bilgiler
            uyarınca Kullanıcı/Müşteri hesapları için Değerlendirme Kontrolleri
            yapar. Bu durum, Norbi’nin elindeki bilgileri ve verileri güncel
            tutmasını ve ayrıca nihai durum tespitinin yapılmasından bu yana
            herhangi bir aksi yönde bir hususun ortaya çıkıp çıkmadığını kontrol
            etmesini sağlamaktadır.
          </ListItem>
          <ListItem>
            c. Değerlendirme yapılırken risk bazlı bir yaklaşım izlenmekte ve
            hakkında yaptırım kararı verilmiş veya engellenmiş gerçek kişilerle
            işlem gerçekleştirilmemektedir.
          </ListItem>
        </List>

        <Heading as="h3" size="sm" mt={4}>
          6.5. Siyasi Nüfuz Sahibi Kişiler
        </Heading>
        <Text mt={2}>
          Bir Müşteri’nin, gerçek faydalanıcının veya Müşteri’nin ticari
          temsilcisinin Siyasi Nüfuz Sahibi Kişi (“SNS”) veya bu kişilerin
          Akrabaları ve Yakın İlişkili Kişileri’nden (“AYİK”) olup olmadığını
          belirlemek için uygun denetimler ve süreçler benimsemekteyiz.
        </Text>
        <Text mt={2}>
          Tespit edilen bir SNS ve/veya AYİK, malvarlığının ve fonların
          kaynaklarının tespit edilmesi ve üst yönetimin onayı da dahil olmak
          üzere Gelişmiş Değerlendirme’ye tabi tutulmaktadır.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          6.6. Gelişmiş Değerlendirme
        </Heading>
        <Text mt={2}>
          Gelişmiş Değerlendirme, bir Müşteri’nin suç gelirlerinin aklanması ve
          terörizmin finansmanı bakımından daha yüksek risk barındırdığı ve
          standart Müşteri tanıma prosedürünün yetersiz olabileceği durumlarda
          başvurulan bir prosedür olarak benimsenmektedir. Bu kapsamda,
          Müşteri’nin onay ve gözetim süreçlerine destek olmak adına birtakım ek
          bilgilerin elde edilmesi gerekmektedir.
        </Text>
        <Text mt={2}>
          Gelişmiş Değerlendirme, Müşteri’nin profiline ve yüksek riskleri
          minimize etmek adına alınması gereken aksiyonlara daha geniş bir
          perspektiften bakmayı sağlayan; objektif, titiz ve kapsamlı
          araştırmayı içermektedir.
        </Text>
        <Text mt={2}>
          Aşağıdaki durumlarda Gelişmiş Değerlendirme'ye başvurulacaktır:
        </Text>
        <List mt={2} spacing={2}>
          <ListItem>
            - SNS'ler ile işlemler veya iş ilişkileri yürütüldüğünde;
          </ListItem>
          <ListItem>
            - ABD Yabancı Varlıklar Denetleme Ofisi ("OFAC") tarafından
            listelenen ülkeler, bölgeler veya kişiler ile bir iş ilişkisi
            kurulduğunda veya bunlarla herhangi bir işlem yürütüldüğünde;
          </ListItem>
          <ListItem>
            - Avrupa Komisyonu tarafından belirlenen yüksek riskli ülkelerden
            gerçek ya da tüzel kişiler ile bir iş ilişkisi kurulduğunda veya
            bunlarla herhangi bir işlem yürütüldüğünde;
          </ListItem>
          <ListItem>
            - Mali Eylem Görev Gücü ("FATF") tarafından yüksek riskli olarak
            tanımlanan ülkelerden gerçek veya tüzel kişiler ile iş ilişkileri
            yürütüldüğünde veya işlemler gerçekleştirildiğinde;
          </ListItem>
          <ListItem>
            - Norbi, kendi risk puanlama matrisini kullanarak bir Müşteri'nin
            riskini "yüksek" olarak değerlendirdiğinde.
          </ListItem>
        </List>
        <Text mt={2}>
          T.C. Hazine ve Maliye Bakanlığı, MASAK, Avrupa Komisyonu, FATF ve OFAC
          tarafından yüksek riskli üçüncü ülkeler arasında sayılan ülkelerde
          mukim gerçek kişiler ile iş ilişkisi kurulduğunda veya bahse konu bu
          kişiler Platform üzerinden işlem gerçekleştirdiğinde, Şirket:
        </Text>
        <List mt={2} spacing={2}>
          <ListItem>
            - Müşteri ve gerçek faydalanıcı hakkında ek bilgi talep etmelidir.
          </ListItem>
          <ListItem>
            - iş ilişkisinin amaçlanan mahiyeti hakkında ek bilgi elde
            etmelidir.
          </ListItem>
          <ListItem>
            - Müşteri'nin ve gerçek faydalanıcının malvarlığının ve/veya
            fonlarının kaynağı hakkında bilgi edinmelidir.
          </ListItem>
          <ListItem>
            - öngörülen veya tamamlanan işlemlerin nedenleri hakkında bilgi
            edinmelidir.
          </ListItem>
          <ListItem>
            - bu Müşteriler ile iş ilişkileri kurmak veya mevcut iş ilişkilerini
            devam ettirmek için üst yönetimden onay almalıdır.
          </ListItem>
          <ListItem>
            - uygulanacak kontrollerin sayısını ve zamanlamasını arttırarak ve
            daha fazla araştırma gerektirecek işlem türlerini seçerek işbu
            Müşterilerle olan iş ilişkilerini gelişmiş bir şekilde sürekli
            izlemelidir.
          </ListItem>
        </List>

        <Heading as="h3" size="sm" mt={4}>
          6.7. Yaptırım Taraması
        </Heading>
        <Text mt={2}>
          Devam eden değerlendirme sürecinin yanı sıra yerleşik sürecimiz bir
          parçası olarak; tüm Müşteriler ve gerçek faydalanıcılar bir tarama
          sürecine tabi tutulur. Yaptırıma uğramış kişi veya kuruluşlar ile
          herhangi bir iş ilişkisi kurulmayacak veya bir işlem
          yürütülmeyecektir.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          6.8. Kayıtların Tutulması
        </Heading>
        <Text mt={2}>
          Mali suç risk yönetimiyle ilgili tüm faaliyetler ve kararları
          belgelemek ve AML’ye uyumluluğu ortaya koyabilmek bakımından doğru
          kayıt tutmak son derece elzemdir. Norbi, tüm Müşteri değerlendirme
          belgelerini ve Müşteri işlemlerine ilişkin kayıtları a) düzenleme
          tarihinden, defter ve kayıtları ise son kayıt tarihinden veya b) son
          işlem tarihinden itibaren 8 (sekiz) yıl süre ile saklamaktadır. Bu
          kayıtlar aşağıda belirtilen hususları içermektedir:
        </Text>
        <List mt={2} spacing={2}>
          <ListItem>a. İş kapsamında risk değerlendirmeleri</ListItem>
          <ListItem>b. Müşteri Risk Değerlendirmeleri</ListItem>
          <ListItem>
            c. Sözleşmeler de dahil olmak üzere üçüncü taraflarla yapılan tüm
            anlaşmalar (Örneğin; Hizmet Seviyesi Sözleşmesi vb.)
          </ListItem>
          <ListItem>
            d. Müşteri’nin kimliğinden elde edilen tevsik edici hususların
            kopyaları ve dayanakları. Bunlar, Norbi tarafından Müşteri’nin son
            işlemi tarihinden itibaren 8 yıl süreyle saklanmaktadır.
          </ListItem>
          <ListItem>
            e. Müşteri işlemlerine ilişkin detaylar, işlemin sona ermesinden
            itibaren 8 yıl süreyle saklanır.
          </ListItem>
          <ListItem>
            f. Müşteri değerlendirme belgeleri. Bunlar, hukuki ilişkinin sona
            erdikten sonra en fazla 8 yıl süreyle saklanır. Muhafaza süresinin
            başlangıç tarihi, Müşteri’nin Norbi nezdindeki hesabının kapatıldığı
            tarihtir.
          </ListItem>
          <ListItem>
            g. Tarih ve katılıma ilişkin detayları da içerecek şekilde, verilen
            tüm AML ve CTF eğitimlerine dair kayıtlar.
          </ListItem>
          <ListItem>
            h. Alınan aksiyonların tarihleri ve detayları da dahil olmak üzere,
            dahili ve harici tüm Şüpheli İşlem Bildirimleri’ne dair kayıtlar. Bu
            kayıtlar özellikle, harici raporun hazırlanmadığı durumlarda
            hazırlanan dahili rapor kapsamında Norbi tarafından ele alınan ve
            değerlendirmeye tabi tutulan bilgilerin ayrıntılarını içermektedir.
          </ListItem>
          <ListItem>
            i. AML/CTF/Yaptırımlar konularının ele alındığı üst düzey yönetim
            toplantılarına dair tutanaklar.
          </ListItem>
        </List>
        <Text mt={2}>
          Bu kayıtlara, gerektiği takdirde yetkili kamu kurum ve kuruluşlarının
          erişebilmesi sağlanır (Örneğin; bir mahkeme kararı doğrultusunda).
        </Text>
      </PolicySection>

      <PolicySection title="7. ŞÜPHELİ İŞLEM BİLDİRİMİ">
        <Text>
          Norbi nezdinde veya Norbi aracılığıyla yapılan veya yapılmaya teşebbüs
          edilen işlemlere konu mal varlığının yasa dışı yollardan elde
          edildiğine veya yasa dışı amaçlarla kullanıldığına dair herhangi bir
          bilgi, şüphe veya şüpheyi gerektirecek bir hususun bulunması hâlinde
          Norbi, bu işlemleri işleme ilişkin şüphenin oluştuğu tarihten itibaren
          en geç on iş günü içerisinde, gecikmesi mümkün olmayan durumlarda ise
          derhal MASAK’a bildirmekle yükümlüdür.
        </Text>
        <Text mt={4}>
          Şüpheli İşlem Bildirimi, fiziken veya elektronik ortamda yapılabilir.{" "}
        </Text>
        <List mt={4} spacing={4}>
          <ListItem>
            a. Fiziken: Şüpheli İşlem Bildirimi’nin fiziken gönderilebilmesi
            için EK-1’de yer alan Şüpheli İşlem Bildirim Formu (“ŞİB Formu”)
            kullanılmalıdır. Norbi, işbu ŞİB Formu’nu Türkçe olarak doldurması
            ve elden veya taahhütlü posta veya faks yoluyla MASAK’a göndermesi
            gerekmektedir. Norbi, ŞİB Formu’nu MASAK’a göndermeden önce formun
            bir nüshasını almalı ve bu nüshayı fiziksel ortamda 8 yıl süreyle
            muhafaza etmelidir.
          </ListItem>
          <ListItem>
            b. Elektronik Ortam: Norbi’nin EMIS.ONLINE’a
            (https://online.masak.gov.tr) erişim yetkisi olması halinde, Norbi
            ŞİB Formu’nu işbu platform üzerinden elektronik formatta sunmalıdır.
            Sistemlerde teknik bir hata olmadığı sürece, Norbi, elektronik
            olarak ŞİB Formu ibraz edebileceği hususunda yetkilendirildiği
            tarihten itibaren kağıt ortamda ŞİB Formu sunamaz. Norbi, formu
            MASAK’a göndermeden önce formun elektronik bir görüntüsü (pdf)
            alınmalı ve bu kopyayı 8 yıl süreyle elektronik ortamda muhafaza
            edilmelidir.
          </ListItem>
        </List>
        <Text mt={4}>
          Norbi, aynı konuda birden fazla gerçek kişi için tek bir ŞİB Formu
          düzenlemelidir. Aynı şüpheli eylemle bağlantılı olduğundan
          şüphelenilen her kişi veya kuruluş için ayrı ŞİB Formları
          gönderilmemelidir. Böyle bir senaryoda, Norbi’nin her kişi veya
          kuruluş için formun ilgili kısımlarını doldurması ve hepsini aynı
          formda göndermesi gerekecektir.
        </Text>
        <Text mt={4}>
          Norbi’nin daha önce gönderilen şüpheli işlem raporunun devamı
          niteliğindeki veya bu şüpheyi destekleyen şüpheli bir faaliyete
          ilişkin yeni işlemler tespit etmesi durumunda, önceki bildirime ek
          olarak yeni bir şüpheli işlem raporu düzenleyerek göndermesi
          gerekmektedir.
        </Text>
        <Text mt={4}>
          Norbi, bir önceki şüpheli işlem bildirimi ile ilgili veya bu şüpheyi
          destekleyen yeni işlemler tespit ederse, önceki bildirime ek olarak
          gönderildiği belirtilerek yeni bir şüpheli işlem bildirimi yapmalıdır.
        </Text>
        <Text mt={4}>
          Şüpheli işlem bildirimine konu işlemle doğrudan veya dolaylı olarak
          ilgili olan kişilerin faaliyetlerinde yasadışı unsurlar olduğu veya
          olabileceğine dair herhangi bir bilgi, bulgu veya şüpheye sahip
          olunması hâlinde, bu yasadışı faaliyetler ŞİB Formu’nun G Bölümü’nde
          belirtilmelidir.
        </Text>
        <Text mt={4}>
          Suça ilişkin şüphe kategorileri “Suça İlişkin Şüphe Kategorileri
          Tablosu” içerisinde yer alan değerlerden seçilerek belirtilmelidir.
          Herhangi bir şüphe kategorisinin varlığına dair bir bilgi yoksa bu
          alan boş bırakılacaktır.
        </Text>
        <Text mt={4}>
          MASAK tarafından sağlanan şüpheli işlem türleri, yalnızca şüpheye
          ihtiyaç olup olmadığının veya makul şüphenin olup olmadığının
          değerlendirilmesinde size yardımcı olmayı amaçlamaktadır. Diğer bir
          deyişle, şüphe doğuran işlem burada sayılan tiplerden herhangi birine
          uymasa dahi Norbi’nin şüpheli işlem bildiriminde bulunması
          gerekecektir.
        </Text>
      </PolicySection>

      <PolicySection title="8. EĞİTİM">
        <Text>
          Norbi, tüm Çalışanlarının, özellikle işlem süreçlerinden ve/veya iş
          ilişkilerini kurmaktan ve/veya yürütmekten sorumlu kişilerin, AML
          bilgisi, yetkinliği ve farkındalığı eğitiminden geçmesini sağlamak
          için kapsamlı bir Suç Gelirlerinin Aklanmasının Önlenmesi ve Mali Suç
          eğitim programına sahiptir. Tüm Çalışanlar, adeta görevlerini icra
          etmeye başlamalarının bir parçası olarak AML eğitiminden geçer.
          Ardından, en az yılda bir kez; ancak gelişen süreçler, değişen hukuki
          gereklilikler yahut süregelen gözetim hatalarının bir parçası olarak
          tespit operasyonel eksiklikler nedeniyle daha yakın periyotlarla
          tazeleme ve takviye eğitimi verilir.
        </Text>

        <Heading as="h3" size="sm" mt={4}>
          AML/CTF Eğitimi: Ana Unsurlar
        </Heading>
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>AML/CTF Eğitiminin Ana Unsurları</Th>
              <Th>Açıklama</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>1</Td>
              <Td>
                Suç Gelirlerini Aklama & Terörizmin Finansmanı ile Mücadeleye
                Genel Bakış, Amaç ve Temel Kavramlar
              </Td>
              <Td>
                <List spacing={2}>
                  <ListItem>
                    i. Suç gelirlerini aklama, yasa dışı yollarla elde edilen
                    malvarlığının mahiyetini, konumunu, kaynağını, sahipliğini
                    veya kontrolünü gizleme veya saklama girişimidir. Suç
                    gelirlerini aklamak, yasa dışı bir faaliyettir.
                  </ListItem>
                  <ListItem>
                    ii. Terörizmin finansmanı, terör eylemleri
                    gerçekleştirmelerini sağlamak için teröristlere veya terör
                    örgütlerine finansal destek sağlanması anlamına gelir.
                  </ListItem>
                  <ListItem>
                    iii. Etkili bir AML/CTF eğitiminin amacı, finansal hizmet
                    sağlayıcının daima a) yürürlükteki mevzuat ve düzenlemeler
                    ile uyumlu olmasını ve b) suç gelirlerini aklamayı önlemeye
                    yönelik sorumluluk ve yükümlülüklerinin bilincinde olmasını
                    sağlamaktır.
                  </ListItem>
                  <ListItem>
                    iv. Suç gelirlerini aklamanın 3 (üç) temel aşaması şöyledir:
                    <List ml={4} mt={2}>
                      <ListItem>
                        1. Yerleştirme: Yerleştirme sırasında, suç
                        faaliyetlerinden elde edilen “kirli” para finansal
                        sisteme yerleştirilir. ilen "kirli" para finansal
                        sisteme yerleştirilir.
                      </ListItem>
                      <ListItem>
                        2. Katmanlama: Katmanlama, finansal işlemin katmanları
                        aracılığıyla fonların kaynağını gizleme sürecidir.
                      </ListItem>
                      <ListItem>
                        3. Entegrasyon: Fonlar katmanlara ayrıldıktan ve artık
                        suç kökenine kadar takip edilemez hale geldikten sonra,
                        finansal sisteme entegre edilirler ve artık “temiz”
                        şekilde, suçlular tarafından kullanıma uygun hale
                        gelirler.
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Td>
            </Tr>
            <Tr>
              <Td>2</Td>
              <Td>Müşterinin Tanınması ("KYC") / Müşteri Katılımı</Td>
              <Td>
                <List spacing={2}>
                  <ListItem>
                    i. Müşteriler tarafından kullanılan malvarlığı kaynaklarının
                    meşruluğu ve doğruluğu AML/CTF programının en kritik
                    hususudur ve AML/CTF programının ne kadar etkili olduğu, KYC
                    sürecinin ne kadar sağlıklı olduğu gerçeğine dayanmaktadır.
                    Bu nedenle, bir Çalışan’ın KYC sürecini nasıl
                    gerçekleştirmesi gerektiği konusunda zihninde herhangi bir
                    karışıklık/belirsizlik olmamalıdır.
                  </ListItem>
                  <ListItem>
                    ii. Müşteri Kimlik Tespit Sürecinin Birincil Taraması:
                    Müşterinin gerçekliğini tespit etmek adına yalnızca “Resmi
                    Geçerliliği Olan Belge” kabul edilmelidir. Kurumsal bir
                    Müşteri olduğu takdirde, şirketin mevcut olduğuna ilişkin
                    belgelerin yanı sıra gerçek faydalanıcılar da taranmalıdır.
                  </ListItem>
                  <ListItem>
                    iii. Müşteri Değerlendirmesi: Müşterilerin taşıyabilecekleri
                    risk derecesine göre sınıflandırılması, yüksek riskli
                    Müşterilerin ve gerçek faydalanıcıların hesapları için
                    Müşteri Değerlendirme (Gelişmiş Müşteri Değerlendirmesi de
                    dahil olmak üzere) faaliyeti yürütülmesi. Ulusal veya
                    uluslararası yaptırımlar için Birleşmiş Milletler, Yabancı
                    Varlıklar Denetleme Ofisi (“OFAC”), Avrupa Birliği, T.C.
                    Dışişleri Bakanlığı, T.C. Hazine ve Maliye Bakanlığı gibi
                    yaptırım (yetkili ve ilgili makam tarafından uygulanan)
                    programlarına karşı tarama gerçekleştirilmelidir.
                  </ListItem>
                  <ListItem>
                    . Müşteri Kabul Çerçevesi: Çalışanlar, Müşteri kabulü ve
                    katılımı ile ilgili Şirket yönergelerinden haberdar
                    olmalıdır. Bir Müşteri’nin reddedilmesi hakkında yol
                    gösterici ilkelere ilişkin bazı örnekler şu şekildedir:
                    <List ml={4} mt={2}>
                      <ListItem>
                        a) Müşteri’nin güvenilir kaynaklar tarafından suçlu veya
                        suç örgütleri ile ilişkili olduğunun tespit edilmiş
                        olması,
                      </ListItem>
                      <ListItem>
                        b) Müşteri’nin güvenilir kaynaklar tarafından terörist
                        olduğu, bir terörist grup veya terör faaliyetleri ile
                        bağlantılı olduğunun tespit edilmiş olması,
                      </ListItem>
                      <ListItem>
                        c) Müşteri’nin, güvenilir kaynaklar tarafından yüksek
                        düzeyde suç veya terör faaliyetleri barındırdığı tespit
                        edilen bir yargı çevresinden olmasıdır.
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>İşlem Gözetimi & Şüpheli İşlem Raporlama</Td>
              <Td>
                Çalışanlar, işbu Politika’nın ekinde belirtilen örnek şüpheli
                işlem türlerinin farkında olmalıdır. Çalışanlar,
                Müşteri’nin/Müşteriler’in, coğrafi konum, işin doğası, gelir
                aralığı, ticaret hacmi gibi farklı faktörleri göz önüne alarak
                “Yüksek”, “Orta” ve “Düşük” risk kategorilerine ayrılması
                gerektiğinin bilincinde olmalıdır. Bu kapsamda işlemler, Şirket
                tarafından belirlenen “kırmızı bayrak” protokolleri veya şüpheli
                işlemlerle ilişkili bir şekilde düzenli olarak gözetlenmelidir.
                Tespit edilen şüpheli işlemler, işleme ilişkin şüphenin oluştuğu
                tarihten itibaren en geç on iş günü içinde, gecikmesi mümkün
                olmayan durumlarda ise derhal MASAK'a bildirilmelidir.
              </Td>
            </Tr>
            <Tr>
              <Td>4</Td>
              <Td>Kayıtların Tutulması</Td>
              <Td>
                Çalışanlar, kayıtların tutulması konusunda hukuki yükümlülükleri
                ve konunun önemi hakkında bilinçli olmalıdır. Müşteri ile ilgili
                her türlü ortamdaki tüm kayıtlar, yükümlülüklerine ve
                işlemlerine ilişkin belgeler düzenleme tarihinden, defter ve
                kayıtlar ise son kayıt tarihinden; kimlik tespitine ilişkin
                belge ve kayıtlar son işlem tarihinden itibaren 8 yıl süreyle
                saklanmalıdır.
              </Td>
            </Tr>
            <Tr>
              <Td>5</Td>
              <Td>
                Emniyet Teşkilatı ile İşbirliği ve Riayet Etmemenin Etkisi
              </Td>
              <Td>
                Çalışanlar, suç gelirlerini aklamanın kanuni bağlamda müeyyide
                doğurabilecek bir suç teşkil ettiğini, emniyet teşkilatı ile iş
                birliğini yapmanın tartışmaya kapalı şekilde elzem olduğunu ve
                bunun Şirket için en yüksek öncelik olduğunun bilincinde
                olmalıdır. Türkiye Cumhuriyeti bir FATF üyesidir ve Türk
                şirketleri diğer FATF üyesi ülkeler ile iş birliği içerisinde
                olmalıdır. Çalışanlar, AML/CTF uyumsuzluklarının sonuçları
                hakkında bilinçli olmalıdır, uyumsuzluk nedeniyle cezai
                kovuşturma başlatılabilir ve sonucunda hapis cezasına
                hükmedilebilir; raporlama yönergelerine uymamak veya yanlış
                yahut eksik bilgi vermenin sonucunda para cezasına
                çarptırılabilir.
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </PolicySection>

      <PolicySection title="9. SORUMLULUKLAR">
        <List spacing={4}>
          <ListItem>
            9.1. Raporlama Görevlisi, Türkiye Cumhuriyeti kanunları ve sair
            düzenlemeler ile uyumluluğu temin etmek adına suç gelirlerinin
            aklanmasının önlenmesi, azaltılması ve işbu Politika’nın
            uygulanmasını sağlamaktadır.
          </ListItem>
          <ListItem>
            9.2. Norbi, tüm Çalışanlarına, suç gelirlerinin aklanmasını önlemeye
            yönelik süreçleri ve faaliyetleri öğrenmesi, anlaması ve uygulaması
            için zaman, kaynak ve her türlü desteği sağlar. Bu kapsamda, bir
            mali suç teşkil ettiğinden şüphe duyulan faaliyetler ile ilgili
            olarak tüm Çalışanların daima dikkatli olması amaçlanmaktadır.
            Herhangi bir şüpheli durumla karşılaşılması halinde ilgili husus
            derhal Raporlama Görevlisi’ne bildirilmelidir.
          </ListItem>
          <ListItem>
            9.3. Kullanıcılar, iç prosedürlerimizi işletip işletmediğimize ve
            bir iç soruşturmanın fiilen devam edip etmediğine bakılmaksızın,
            endişelerini doğrudan Raporlama Görevlisi’ne bildirebilirler.
          </ListItem>
        </List>
        <Text mt={4}>
          Tüm Kullanıcılar, Raporlama Görevlisi'ne bildirimde bulunmak için
          aşağıdaki e-posta adresini kullanabilirler:
        </Text>
        <Text mt={2} fontWeight="bold">
          E-Posta Adresi: destek@norbiapp.com
        </Text>
        <List spacing={4} mt={4}>
          <ListItem>
            9.4. Raporlama Görevlisi, tüm dahili şüpheli işlem bildirimlerini
            inceler ve MASAK’a resmi bir Şüpheli İşlem Bildirimi yapılması
            gerekip gerekmediğine karar verir. Raporlama Görevlisi,
            araştırmalarının ve değerlendirmelerinin ardından ya Şüpheli İşlem
            Bildirimi’nde bulunmamasının gerekçelerini ve kanıtlarını şüpheye
            yer bırakmayacak şekilde kanıtlar ya da en kısa sürede MASAK’a yasal
            prosedüre uygun bir Şüpheli İşlem Bildirimi’nde bulunulur.
          </ListItem>
          <ListItem>
            9.5. Yönetim Kurulu, suç gelirlerinin aklanmasına, terörizmin
            finansmanına ve mali yaptırımlara ilişkin ihlalleri önlemek ve
            tespit etmek; buna ek olarak AML düzenlemeleri ile uyumluluğu
            sağlamak ve bağlantılı riskleri yönetmek için Norbi’nin bir
            çerçeveye sahip olmasını sağlamakla yükümlüdür.
          </ListItem>
        </List>
      </PolicySection>

      <PolicySection title="10. DIŞ KAYNAK KULLANIMI">
        <Text>Norbi aşağıdaki faaliyet için dış kaynak kullanmaktadır:</Text>
        <Text mt={2} fontWeight="bold">
          • Müşterinin Kimliğinin Doğrulanması: Sum and Substance Ltd (UK)
        </Text>
        <Text mt={2}>
          • AML Kontrolleri: Defy Dijital Yazılım Hizmetleri A.Ş.
        </Text>
      </PolicySection>

      <PolicySection title="11. GÖZDEN GEÇİRME">
        <Text>
          İşbu Politika, mevzuat değişiklikleri, ticari hayattaki gelişmeler ve
          endüstri standartları gibi çeşitli faktörlere bağlı olarak düzenli
          aralıklarla gözden geçirilmektedir.
        </Text>
      </PolicySection>
      <Flex justifyContent="space-between" mb={4} mt={12}>
        <VStack align="flex-start">
          <Text fontSize="lg" fontWeight="bold">
            EK-1
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            ŞÜPHELİ İŞLEM BİLDİRİM FORMU
          </Text>
        </VStack>
        <VStack align="flex-end">
          <Text>Mali Suçları Araştırma Kurulu Başkanlığı</Text>
          <Text>Maliye Bakanlığı, N Blok</Text>
          <Text>Dikmen Cad. 06100</Text>
          <Text>Dikmen/ANKARA</Text>
          <Text>Tel: +90 (312) 415 37 67</Text>
          <Text>Fax: +90 (312) 415 25 35 - 36</Text>
        </VStack>
      </Flex>

      {/* Form Section A */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        A- FORMU DÜZENLEYEN YÜKÜMLÜNÜN
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">Adı Soyadı- Unvanı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex>
          <Text flex="1">Yükümlü Grubu:</Text>
          <Input flex="2" size="sm" />
        </Flex>
      </Box>

      {/* Form Section B */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        B- BİLDİRİME İLİŞKİN BİLGİLER
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">Bildirim Tarihi:</Text>
          <Input flex="1" size="sm" />
          <Text flex="1">Evrak Kayıt No:</Text>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Yeni</Checkbox>
          <Checkbox flex="1">Ek</Checkbox>
          <Text flex="1">Ek ise Önceki Bildirimin Tarihi:</Text>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex>
          <Text flex="1">Bildirimin Aciliyeti:</Text>
          <Checkbox flex="1">Normal</Checkbox>
          <Checkbox flex="1">İvedi</Checkbox>
          <Checkbox flex="1">Çok İvedi</Checkbox>
        </Flex>
      </Box>

      {/* Form Section C */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        C- ŞÜPHELİ İŞLEMİ YAPAN GERÇEK KİŞİNİN
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">İşlemin Kimin Adına Yapıldığı</Text>
          <Checkbox flex="1">Kendi Adına</Checkbox>
          <Checkbox flex="1">Başkası Adına</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Kimliği Sahte mi?</Text>
          <Checkbox flex="1">Evet</Checkbox>
          <Checkbox flex="1">Hayır</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Soyadı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Doğum Yeri ve Tarihi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Anne Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Baba Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Cinsiyeti:</Text>
          <Checkbox flex="1">Erkek</Checkbox>
          <Checkbox flex="1">Kadın</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Uyruğu:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">T.C. Kimlik No:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Kimlik Belgesinin Türü ve Numarası</Text>
          <Checkbox flex="1">Nüfus Cüzdanı</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Sürücü Belgesi</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Pasaport</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">İkamet Tezkeresi</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Diğer</Checkbox>
          <Input flex="1" size="sm" placeholder="Adı ve Numarası:" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Adresi:</Text>
          <Input flex="2" size="sm" placeholder="Ev:" />
          <Input flex="2" size="sm" placeholder="İş:" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Telefon Numarası:</Text>
          <Checkbox flex="1">Ev Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Cep Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">İş Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Faks Numarası:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Elektronik Posta Adresi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Mesleği:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Varsa Ek Bilgi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
      </Box>
      {/* Section D */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        D- ADINA İŞLEM YAPILAN GERÇEK KİŞİNİN
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">Kimliği sahte mi?</Text>
          <Checkbox flex="1">Evet</Checkbox>
          <Checkbox flex="1">Hayır</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Soyadı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Doğum Yeri ve Tarihi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Anne Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Baba Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Cinsiyeti:</Text>
          <Checkbox flex="1">Erkek</Checkbox>
          <Checkbox flex="1">Kadın</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Uyruğu:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">T.C. Kimlik No:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Kimlik Belgesinin Türü ve Numarası</Text>
          <Checkbox flex="1">Nüfus Cüzdanı</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Sürücü Belgesi</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Pasaport</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">İkamet Tezkeresi</Checkbox>
          <Input flex="1" size="sm" placeholder="No:" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Diğer</Checkbox>
          <Input flex="1" size="sm" placeholder="Adı ve Numarası:" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Adresi:</Text>
          <Input flex="2" size="sm" placeholder="Ev:" />
          <Input flex="2" size="sm" placeholder="İş:" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Telefon Numarası:</Text>
          <Checkbox flex="1">Ev Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">Cep Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">İş Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Faks Numarası:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Elektronik Posta Adresi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Mesleği:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Varsa Ek Bilgi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
      </Box>

      {/* Section E */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        E- ADINA İŞLEM YAPILAN TÜZEL KİŞİNİN/TÜZEL KİŞİLİĞİ OLMAYAN TEŞEKKÜLÜN
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">Kimliğe esas belge sahte mi?</Text>
          <Checkbox flex="1">Evet</Checkbox>
          <Checkbox flex="1">Hayır</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Unvanı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Varsa İşletme Adı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Vergi Kimlik Numarası:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Sicil Numarası:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Kurulduğu Ülke:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Kurulduğu Yer:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Kuruluş Yılı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Adresi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Telefon Numarası:</Text>
          <Checkbox flex="1">Cep Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Checkbox flex="1">İş Telefonu</Checkbox>
          <Input flex="1" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Faks Numarası:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Elektronik Posta Adresi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Web Sitesi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Varsa Ek Bilgi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
      </Box>
      {/* Section F */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        F- ŞÜPHELİ İŞLEM BİLGİLERİ
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">İşlem Tarihi:</Text>
          <Input flex="1" size="sm" placeholder=".../.../...." />
          <Checkbox flex="1">Tek işlemde</Checkbox>
          <Checkbox flex="1">Bağlantılı işlemlerde</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">İşlemde Parasal Değer / Hareket var mı?</Text>
          <Checkbox flex="1">Evet</Checkbox>
          <Checkbox flex="1">Hayır</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">İşlemin Hesaba Bağlılığı</Text>
          <Checkbox flex="2">Hesaba bağlı</Checkbox>
          <Checkbox flex="2">Hesaba bağlı değil</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">İşlemin Yapıldığı Yer:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">İşlem Türü:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">İşlem Tutarı:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">İşlemin Yapılma Yeri veya Yöntemi:</Text>
          <Checkbox flex="1">Şube</Checkbox>
          <Checkbox flex="1">ATM</Checkbox>
          <Checkbox flex="1">İnternet bankacılığı</Checkbox>
          <Checkbox flex="1">Telefon bankacılığı</Checkbox>
          <Checkbox flex="1">Diğer</Checkbox>
        </Flex>
      </Box>

      {/* Section G */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        G- ŞÜPHELİ İŞLEMİ İLGİLENDİREN HESAP BİLGİLERİ
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Flex mb={2}>
          <Text flex="1">Hesap Türü:</Text>
          <Checkbox flex="1">Çek</Checkbox>
          <Checkbox flex="1">Kiralık kasa</Checkbox>
          <Checkbox flex="1">Vadeli döviz</Checkbox>
          <Checkbox flex="1">Vadeli döviz</Checkbox>
          <Checkbox flex="1">Vadesiz döviz</Checkbox>
          <Checkbox flex="1">Vadesiz mevduat</Checkbox>
          <Checkbox flex="1">Yatırım</Checkbox>
          <Checkbox flex="1">Diğer</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesabın bulunduğu mali kurum:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesap No:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">IBAN:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesap Sahibi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesabın Açılış Tarihi:</Text>
          <Input flex="2" size="sm" placeholder=".../.../...." />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesabın Kapanış Tarihi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Son Hesap Bakiyesi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesabın Bulunduğu Şube:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesabın Döviz Cinsi:</Text>
          <Input flex="2" size="sm" />
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Hesap Üzerinde Yasal Kısıtlama Var mı?</Text>
          <Checkbox flex="1">Evet</Checkbox>
          <Checkbox flex="1">Hayır</Checkbox>
        </Flex>
        <Flex mb={2}>
          <Text flex="1">Yasal Kısıtlama Açıklaması:</Text>
          <Input flex="2" size="sm" />
        </Flex>
      </Box>
      {/* Section H */}
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        H- ŞÜPHELİ İŞLEM TİPİ
      </Text>
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Table variant="simple" colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th color="white">ŞÜPHELİ İŞLEM TİPLERİ</Th>
              <Th color="white">TİP NUMARASI</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>GENEL MAHİYETTE ŞÜPHELİ İŞLEM TİPLERİ</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Müşteri Profiline İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>İşlemlere İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>SEKTÖREL MAHİYETTE ŞÜPHELİ İŞLEM TİPLERİ</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Bankacılık Sektörüne İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Sigorta Sektörüne İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Sermaye Piyasalarına İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Yetkili Müesseselere İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>İkrazatçılara İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Faktoring Şirketlerine İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Finansal Kiralama Şirketlerine İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Tüketici Finansman Şirketlerine İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>PTT ve Kargo Şirketlerine İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Gayrimenkul Sektörüne İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>TERÖRÜN FİNANSMANI İLE BAĞLANTILI ŞÜPHELİ İŞLEM TİPLERİ</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>
                Terör Örgütleri İle Bağlantısından Şüphelenilen Kişiler veya
                Riskli Ülkelere Gerçekleştirilen İşlemlere İlişkin Tipler
              </Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Kâr Amacı Gütmeyen Kuruluşlara İlişkin Tipler</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {/* Açıklama Section */}
      <Box border="1px" borderColor="gray.500" p={4} mb={4}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          AÇIKLAMA
        </Text>
        <Input
          size="sm"
          placeholder="Buraya açıklama ekleyebilirsiniz"
          height="150px"
        />
      </Box>

      {/* Signature Section */}
      <Box border="1px" borderColor="gray.500" p={4}>
        <Flex justifyContent="space-between">
          <VStack align="flex-start">
            <Text>Uyum Görevlisinin</Text>
          </VStack>
          <VStack align="flex-end">
            <Text>Adı, Soyadı ve İmzası</Text>
          </VStack>
        </Flex>
      </Box>
      <Box p={6} bg="transparent" color="white" borderRadius="md" width="100%">
        <Text mb={4}>
          [1] 16/9/2008 gün ve 26999 sayılı Resmi Gazete'de Yayımlanmıştır.
        </Text>
        <Text mb={4}>
          [2] Bankalar ile ilk defa uyum görevlisi atanacak yükümlülerde, söz
          konusu atama işlemi gerçekleştirilinceye kadar, şüpheli işlem
          bildirimlerinin 3/6/1997 tarihli ve 97/9523 sayılı Bakanlar Kurulu
          Kararıyla yürürlüğe konulan Karar'ın Aklanmasının Önlenmesine Dair
          4208 sayılı Kanunun Uygulanmasına İlişkin Yönetmelik ve ilgili Genel
          Tebliğler uyarınca bildirilmesine devam olunur.
        </Text>
        <Text mb={4}>
          [3] Posta ve Telgraf Teşkilatı Genel Müdürlüğü’nün şüpheli işlemleri
          uyum görevlisi aracılığıyla bildirme yükümlülüğü bankacılık
          faaliyetiyle sınırlı olup, diğer faaliyetleriyle ilgili olan ve bu
          Tebliğin (5.1.3) bölümünde yer alan şüpheli işlem bildirim
          yükümlülüğünün bu uyum görevlisi üzerinden yapılması da mümkündür.
        </Text>
        <Text mb={4}>
          [4] 18/10/2008 gün ve 26323 sayılı Resmi Gazete 'de yayımlanmıştır.
        </Text>

        <Text mb={4}>
          [i] Gerçek kişi yükümlüler için adı soyadı, tüzel kişi yükümlüler için
          ticaret unvanı, tüzel kişiliği olmayan yükümlüler için teşekkülün adı
        </Text>
        <Text mb={4}>
          [ii] Tedbirler Yönetmeliğinin 4 üncü maddesinde sayılan yükümlülerden,
          dahil olunan grup
        </Text>
        <Text mb={4}>
          [iii] Gerçek kişi yükümlüler için T.C. Kimlik Numarası, diğer yükümlü
          grupları için vergi kimlik numarası
        </Text>
        <Text mb={4}>
          [iv] Birden fazla kişi tarafından gerçekleştirilen şüpheli işlemlerde
          işlemi yapan her bir gerçek kişinin bilgileri
        </Text>
        <Text mb={4}>
          [v] Adına işlem yapılan birden fazla gerçek kişinin olduğu şüpheli
          işlemlerde her bir adına işlem yapılanın bilgileri
        </Text>
        <Text mb={4}>
          [vi] Adına işlem yapılan birden fazla tüzel kişinin olduğu şüpheli
          işlemlerde her bir adına işlem yapılanın bilgileri
        </Text>
        <Text mb={4}>
          [vii] Ticaret siciline kayıtlı tüzel kişiler için ticaret unvanı,
          dernek, vakıf, sendika, konfederasyon, tüzel kişiliği olmayan
          teşekküller için bunların adı, siyasi partiler için siyasi partinin
          ilgili biriminin adı
        </Text>
        <Text mb={4}>
          [viii] Ticaret siciline kayıtlı tüzel kişiler için ticaret sicil
          numarası, dernekler için kütük numarası, vakıflar için merkezi sicil
          kayıt numarası, sendika ve konfederasyonlar için sicil numarası
        </Text>
        <Text mb={4}>
          [ix] İşlemin yapıldığı il/ilçe bilgileri ile varsa şube, acenta vb.
          bağlı birimlerin adı
        </Text>
        <Text mb={4}>[x] Para birimi de belirtilmek suretiyle</Text>
        <Text mb={4}>
          [xi] Tedbirler Yönetmeliğinin 4 üncü maddesinin birinci fıkrasının (a)
          ila (h) bentlerinde sayılan yükümlülerce (Bankacılık faaliyeti ile
          sınırlı olmak üzere PTT dahil) doldurulacaktır.
        </Text>
        <Text mb={4}>
          [xii] EK-1’de yer alan şüpheli işlem tiplerinden uygun olan bir veya
          bir kaçının yine EK-1’de belirlenen numarası
        </Text>
        <Text mb={4}>
          [xiii] Uyum görevlisi atanması zorunlu olmayan yükümlülerde form;
          işlemi gerçekleştiren veya tespit eden kişi veya görevli ile birlikte
          varsa bu işlemin sonuçlandırılması konusunda imza yetkisine sahip bir
          kişi tarafından imzalanır.
        </Text>
      </Box>
    </Box>
  );
};

export default AMLKYCPolicy;
