import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#010101",
    secondary: "#333",
    pvPurple: "#753CC5",
    pvPink: "#DB72EE",
    pvBlue: "#5263F0",
    success: "#4caf50",
    error: "#f44336",
    warning: "#ffeb3b",
    info: "#2196f3",
    muted: "#222",
    background: {
      default: "#111",
      paper: "#f5f5f5",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
      },
      variants: {
        pvGoWallet: {
          bg: "#111",
          color: "white",
          textTransform: "none",
          fontSize: { base: "16px", md: "16px", lg: "18px", xl: "20px" },
          borderRadius: "10px",
          border: "1px solid #333",
          _hover: {
            bg: "#333",
          },
          _active: {
            bg: "#888",
          },
        },
        pvLang: {
          bg: "#111",
          color: "white",
          w: "45px",
          h: "45px",
          textTransform: "none",
          borderRadius: "10px",
          border: "1px solid #333",
          _hover: {
            bg: "#333",
          },
          _active: {
            bg: "#888",
          },
        },
        pvGradient: {
          bg: "transparent",
          color: "white",
          borderRadius: "15px",
          isolation: "isolate",
          overflow: "hidden",
          width: { base: "55%", md: "40%", lg: "360px" },
          height: { base: "50px", md: "55px", lg: "60px" },
          position: "relative",
          textTransform: "none",
          fontSize: { base: "14px", md: "18px", lg: "22px", xl: "24px" },
          fontWeight: "bold",
          _before: {
            content: '""',
            position: "absolute",
            inset: "0",
            borderRadius: "inherit",
            padding: "1px", // Border width
            background: "linear-gradient(to right, #529BF0, #9256E7)",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            maskComposite: "exclude",
            zIndex: -1,
          },
          _hover: {
            _after: {
              content: '""',
              position: "absolute",
              inset: "0",
              borderRadius: "inherit",
              background: "linear-gradient(to right, #529BF0, #9256E7)",
              opacity: 1, // Değişiklik burada: 0'dan 1'e çevrildi
              transition: "opacity 0.3s",
              zIndex: -1,
            },
            // transform: "scale(1.05)",
            // boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
          },
        },
        pvFooter: {
          bg: "none",
          color: "white",
          textTransform: "none",
          borderRadius: "10px",
          border: "2px solid #753CC5",
          paddingY: ["2", "4", "5", "6"],
          paddingX: ["4", "4", "3", "4"],
          _hover: {
            bg: "#231734",
          },
          _active: {
            bg: "#130A21",
          },
        },
        pvTransparent: {
          w: "auto",
          h: "45px",
          bg: "rgba(255, 255, 255, 0.15)",
          color: "white",
          textTransform: "none",
          boxShadow: "4px 6px 10px rgba(0, 0, 0, 0.3)",
          fontSize: ["13px", "14px", "15px", "16px", "18px"],
          paddingY: ["2", "4", "5", "6"],
          paddingX: ["4", "4", "3", "4"],
          borderRadius: "10px",
          _hover: {
            bg: "rgba(255, 255, 255, 0.20)",
          },
          _active: {
            bg: "rgba(255, 255, 255, 0.30)",
          },
        },
        pvTransparent2: {
          w: "auto",
          bg: "rgba(255, 255, 255, 0.15)",
          color: "white",
          textTransform: "none",
          boxShadow: "4px 6px 10px rgba(0, 0, 0, 0.3)",
          fontSize: ["13px", "14px", "15px", "16px", "18px"],
          paddingY: ["5"],
          paddingX: ["3"],
          borderRadius: "10px",
          _hover: {
            bg: "rgba(255, 255, 255, 0.20)",
          },
          _active: {
            bg: "rgba(255, 255, 255, 0.30)",
          },
        },
      },
    },
    Table: {
      variants: {
        pvPriceTable: {
          bg: "#1a1a1a",
          color: "white",
          th: {
            bg: "#000",
            color: "white",
            borderBottom: "1px solid #444",
            fontSize: "16px",
          },
          td: {
            bg: "#1a1a1a",
            color: "white",
            borderBottom: "1px solid #444",
          },
          tr: {
            _hover: {
              bg: "#444",
            },
          },
        },
      },
    },
  },
  textStyles: {
    h1: {
      fontSize: ["22px", "24px", "30px", "36px", "48px"],
      fontWeight: "bold",
      lineHeight: "110%",
    },
    h2: {
      fontSize: ["20px", "22px", "28px", "34px", "35px"],
      fontWeight: "semibold",
      lineHeight: "110%",
    },
    h3: {
      fontSize: ["18px", "20px", "24px", "28px", "30px"],
      fontWeight: "medium",
      lineHeight: "110%",
    },
    lead: {
      fontSize: ["14px", "16px", "18px", "20px"],
      fontWeight: "normal",
      lineHeight: "normal",
    },
    body: {
      fontSize: ["12px", "14px", "16px", "18px"],
      fontWeight: "normal",
      lineHeight: "normal",
    },
    small: {
      fontSize: ["12px", "14px", "15px", "16px"],
      fontWeight: "normal",
      lineHeight: "normal",
    },
    caption: {
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: "normal",
    },
    subCaption: {
      fontSize: "10px",
      fontWeight: "light",
      lineHeight: "normal",
    },
    overline: {
      fontSize: "12px",
      fontWeight: "bold",
      letterSpacing: "1px",
      textTransform: "uppercase",
      lineHeight: "normal",
    },
    banner: {
      fontSize: ["16px", "20px", "28px", "32px", "40px"],
      fontWeight: "bold",
    },
    banner2: {
      fontSize: ["13px", "14px", "18px", "20px", "23px"],
      fontWeight: "normal",
    },
    footerLink: {
      fontSize: ["12px", "13px", "14px", "15px", "16px"],
      fontWeight: "normal",
      lineHeight: "normal",
    },
    footerLinkTitle: {
      fontSize: ["13px", "14px", "15px", "16px", "18px"],
      fontWeight: "bold",
      lineHeight: "normal",
    },
  },

  fonts: {
    heading: `'Hanken Grotesk', sans-serif`,
    body: `'Hanken Grotesk', sans-serif`,
  },
});

export default theme;
