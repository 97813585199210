import React, { useState } from "react";
import {
  Box,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  useBreakpointValue,
} from "@chakra-ui/react";

const StyledText = ({
  children,
  color = "white",
  sizes = ["24px", "30px", "24px", "28px", "30px"],
  mb,
}) => (
  <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
    {children}
  </Text>
);

const faqData = [
  {
    question: "Norbi Nedir?",
    answer:
      "Norbi, yenilikçi bir kripto alım satım cüzdanıdır. Kullanıcıların dijital varlıklarını güvenli, kolay ve merkeziyetsiz bir şekilde yönetmelerine olanak tanır.",
  },
  {
    question: "Norbi'nin Avantajları ve Öne Çıkan Özellikleri Nelerdir?",
    answer:
      "• Kullanıcılar varlıklarının gerçek sahibi\n • Yüksek likitide \n • En iyi fiyat ve rota\n • Yapay zeka NorbiAI ile finansal okuryazarlığınızı destekler\n • Kullanıcı dostu arayüzü ile basit\n • Yüksek güvenlik ve teknoloji\n",
  },
  {
    question: "Norbi'yi Kimler Kullanabilir?",
    answer:
      "Norbi’yi Türk Vatandaşı olan herkes kullanabilir. Blokzinciri teknolojisine aşina olanlardan yeni başlayanlara kadar herkes tarafından kullanılabilir.",
  },
  {
    question: "Norbi Varlıklarımın Güvenliğini Nasıl Sağlar?",
    answer:
      "Norbi, varlıklarınızı sektördeki en yüksek güvenlik standartlarıyla korur:\n • MPC teknolojisi ile merkeziyetsiz cüzdan. Dilediğiniz zaman dahili merkeziyetsiz cüzdanınıza varlıklarınızı çekebilirsiniz.\n • MFA (Çok Faktörlü Kimlik Doğrulama)\n • KYC/AML (Müşteri Tanıma ve Kara Para Aklama Önleme)",
  },
  {
    question: "Norbi Kullanmaya Nasıl Başlayabilirim?",
    answer:
      "• Norbi’nin resmi <a href='https://web.norbi.app'style='text-decoration: underline;'> web uygulamasına</a> gidin.\n • Kullanıcı kaydınızı “Hesap Oluştur”a tıklayarak ve bilgilerinizi girerek tamamlayın.\n • Kimliğinizi doğrulayın ve işlem yapmaya başlayın.\n • Mobile App çok yakında!",
  },
  {
    question: "Norbi'de Yapabileceğim İşlemler Nelerdir?",
    answer:
      "• Kripto alım-satım\n • Varlıkların güvenli saklanması\n • Kripto/kripto, kripto/TL, TL/kripto dönüşümleri\n • DeFi (Merkeziyesiz Finans) uygulamalarına dahili erişim\n • Türk Lirası yatırma/çekme\n • E-posta veya telefon numarası aracılığıyla hızlı transferler\n • İşlem geçmişi ve portföy takibi",
  },
  {
    question: "Norbi Kişisel Verilerimin Gizliliğini Nasıl Sağlar?",
    answer:
      "Norbi, kişisel verilerin gizliliğini sağlamak için KVKK'ya (Kişisel Verilerin Korunması Kanunu) uygun hareket eder. Veriler, kullanıcı onayı ile toplanır ve işlenir. Veriler, güvenli sunucularda saklanır ve gerekli teknik ve idari tedbirler alınır. Kullanıcılar, verilerine erişme, düzeltme, silme ve işleme itiraz etme haklarına sahiptir. Detaylar için KVKK Aydınlatma Metni sayfasını ziyaret edebilirsiniz.",
  },
  {
    question: "Norbi ile Kripto Al/Sat Yapabilir miyim?",
    answer:
      "Üye olduktan sonra Norbi uygulaması üzerinden ana sayfada yer alan “Kripto Alım Satım” butonuna tıklayarak hızlıca kripto alım satım emirlerinizi gerçekleştirebilirsiniz. Dilerseniz Kolay al/sat seçeneğiyle tek adımda işlem yapabilirsiniz.",
  },
];

const FAQ = () => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const splitFaqData = (data) => {
    const mid = Math.ceil(data.length / 2);
    return [data.slice(0, mid), data.slice(mid)];
  };

  const [leftColumn, rightColumn] = splitFaqData(faqData);

  const baseBg = useBreakpointValue({
    base: `
      linear-gradient(135deg, #333 0%, #333 20%, transparent 51%),
      linear-gradient(315deg, #333 0%, #333 20%, transparent 51%),
      radial-gradient(circle at top right, rgba(146, 86, 231, 0.9), transparent 70%),
      radial-gradient(circle at bottom left, rgba(146, 86, 231, 0.9), transparent 70%)
    `,
    md: "#222",
  });

  return (
    <Box
      bg="transparent"
      py={4}
      mt={12}
      color="white"
      px={{ base: "5%", lg: 32 }}
    >
      <VStack spacing={4} align="stretch" mx="auto">
        <Box textAlign="center">
          <StyledText color="#9256E7" fontWeight="bold" mb={4}>
            MERAK ETTİKLERİNİZ
          </StyledText>
          <Text
            fontSize={{ base: "24px", sm: "50px", md: "40px", lg: "50px" }}
            fontWeight="bold"
            sx={{ WebkitFontSmoothing: "antialiased" }}
            color={"rgb(236, 236, 236)"}
          >
            Sorularınız mı var?
          </Text>
        </Box>

        <Accordion allowToggle>
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={{ base: 0, md: 8 }}
          >
            {[leftColumn, rightColumn].map((column, columnIndex) => (
              <GridItem key={columnIndex}>
                {column.map((item, index) => (
                  <Box
                    key={index}
                    position="relative"
                    mb={4}
                    borderRadius="16px"
                    overflow="hidden"
                    bg={baseBg}
                    p={0.5}
                    onMouseEnter={() => !isMobile && setIsHovered(true)}
                    onMouseLeave={() => !isMobile && setIsHovered(false)}
                    _hover={
                      isMobile
                        ? {}
                        : {
                            "&::before": {
                              transform: "scale(1.5)",
                              opacity: 1,
                            },
                          }
                    }
                    sx={
                      isMobile
                        ? {}
                        : {
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              background:
                                "radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(146, 86, 231, 0.9), transparent 60%)",
                              transition: "transform 0.3s, opacity 0.3s",
                              pointerEvents: "none",
                              opacity: 0,
                            },
                          }
                    }
                    onMouseMove={
                      isMobile
                        ? undefined
                        : (e) => {
                            const rect =
                              e.currentTarget.getBoundingClientRect();
                            e.currentTarget.style.setProperty(
                              "--mouse-x",
                              `${e.clientX - rect.left}px`
                            );
                            e.currentTarget.style.setProperty(
                              "--mouse-y",
                              `${e.clientY - rect.top}px`
                            );
                          }
                    }
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      position="relative"
                      borderRadius="15px"
                      overflow="hidden"
                      bg="#111"
                      flex="1"
                      display="flex"
                      flexDirection="column"
                    >
                      <AccordionItem
                        border="none"
                        bg="#000"
                        borderRadius="15px"
                        position="relative"
                        zIndex={1}
                        p={4}
                      >
                        <AccordionButton>
                          <Box
                            flex="1"
                            textAlign="left"
                            fontWeight="bold"
                            fontSize="lg"
                          >
                            {item.question}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel px={8} py={4}>
                          {item.answer.split("\n").map((line, i) => (
                            <Text
                              key={i}
                              mb={2}
                              dangerouslySetInnerHTML={{ __html: line }}
                            />
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Box>
                  </Box>
                ))}
              </GridItem>
            ))}
          </Grid>
        </Accordion>
      </VStack>
    </Box>
  );
};

export default FAQ;
