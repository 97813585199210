import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

import WelcomeNorbi from "./WelcomeNorbi";
import AboutUs from "./AboutUs";
import MainFeatures from "./MainFeatures";
import Advantage from "./Advantage";
import CardSlider from "../components/CardsSlider";
import HowToStart from "./HowToStart";
import FAQ from "./FAQ";

import { Trade } from "polyverse-sdk-dev/dist/api/trade";

function Home() {
  const [arrayCount, setArrayCount] = useState("197");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTokenCount = async () => {
      try {
        const response = await Trade.spot.symbols("TRY");
        setArrayCount(response.length);
      } catch (error) {
        console.error("Failed to fetch tokens:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTokenCount();
  }, []);

  return (
    <>
      <Box pt={{ base: 28, sm: 0 }}>
        <WelcomeNorbi />
        <AboutUs />
        <CardSlider arrayCount={arrayCount} />
        <MainFeatures />
        <FAQ />
        <HowToStart />
        <Advantage loading={loading} arrayCount={arrayCount} />
      </Box>
    </>
  );
}

export default Home;
