import React from "react";
import { Box, Text, VStack, HStack, Center, Flex } from "@chakra-ui/react";

const StyledText = ({
  children,
  color = "white",
  sizes = ["24px", "30px", "24px", "28px", "30px"],
  mb,
}) => (
  <Text
    fontSize={sizes}
    fontWeight="bold"
    color={color}
    mb={mb}
    sx={{ WebkitFontSmoothing: "antialiased" }}
  >
    {children}
  </Text>
);

const StyledBox = ({
  children,
  bg = "rgba(51, 51, 51, 0.2)",
  width = "30%",
}) => (
  <Box
    bg={bg}
    px={4}
    py={8}
    borderRadius="15"
    border="1px solid #333"
    flex={1}
    textAlign="center"
    alignContent="center"
    width={{ base: "100%", md: width, lg: "300" }}
    sx={{ WebkitFontSmoothing: "antialiased" }}
  >
    {children}
  </Box>
);

function AboutUs() {
  return (
    <Box
      bg="transparent"
      mt={20}
      color="white"
      px={{ base: "5%", lg: 32 }}
    >
      <VStack spacing={8} align="stretch">
        <Center flexDirection="column" mb={0} mt={4}>
          <HStack>
            <Box bg="#9256E7" p={2} borderRadius="15">
              <Text
                fontSize={{
                  base: "24px",
                  sm: "50px",
                  md: "40px",
                  lg: "50px",
                  xl: "60px",
                }}
                fontWeight="bold"
                sx={{ WebkitFontSmoothing: "antialiased" }}
                color={"rgb(236, 236, 236)"}
              >
                Norbi
              </Text>
            </Box>
            <Text
              fontSize={{
                base: "24px",
                sm: "50px",
                md: "40px",
                lg: "50px",
                xl: "60px",
              }}
              fontWeight="bold"
              sx={{ WebkitFontSmoothing: "antialiased" }}
              color={"rgb(236, 236, 236)"}
            >
              Çok Farklı!
            </Text>
          </HStack>
        </Center>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justify="space-between"
          gap={4}
          px={{ base: "5%", lg: 30 }}
        >
          <StyledBox>
            <StyledText>Dünya Fiyatları ile Aynı Fiyat</StyledText>
            <StyledText color="#9256E7">Yüksek Likidite</StyledText>
          </StyledBox>
          <StyledBox bg="#111">
            <StyledText>Türkiye'nin Akıllı Cüzdanı</StyledText>
            <StyledText color="#9256E7">Dahili Merkeziyetsiz Cüzdan</StyledText>
          </StyledBox>
          <StyledBox>
            <StyledText>Kolay Kripto Al-Sat</StyledText>
            <StyledText color="#9256E7">Uzman Olmanıza Gerek Yok</StyledText>
          </StyledBox>
          <StyledBox bg="#111">
            <StyledText>Varlıklarının</StyledText>
            <StyledText color="#9256E7">Gerçek Sahibi Ol!</StyledText>
          </StyledBox>
        </Flex>
      </VStack>
    </Box>
  );
}

export default AboutUs;
