import { Box, Heading, Text, List, ListItem, Link } from "@chakra-ui/react";

const TradeRegistryInfo = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Ticaret Sicili Bilgileri
      </Heading>

      <List spacing={3}>
        <ListItem>
          <Text>
            <strong>Ticaret Unvanı:</strong> YENKİDO BİLİŞİM HİZMETLERİ TİCARET
            A.Ş.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Ticaret Sicil Memurluğu:</strong> İstanbul Ticaret Sicil
            Müdürlüğü
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Ticaret Sicil No:</strong> 1031210
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Mersis No:</strong> 0949076075300001
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Şirket Tescil Tarihi:</strong> 20.08.2021
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Vergi Dairesi:</strong> Şişli Vergi Dairesi
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Vergi Numarası:</strong> 9490760753
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Adres:</strong> Halaskargazi Cd. No: 38-66E İç Kapı No:215,
            Nişantaşı Lotus, 34371 Şişli-İstanbul
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Web Sayfası:</strong>{" "}
            <Link href="https://norbi.app/" isExternal>
              https://norbi.app/
            </Link>
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <strong>Sektör:</strong> Bilgisayar programlama faaliyetleri
            (sistem, veri tabanı, network, web sayfası vb. yazılımları ile
            müşteriye özel yazılımların kodlanması vb)
          </Text>
        </ListItem>
      </List>
    </Box>
  );
};

export default TradeRegistryInfo;
