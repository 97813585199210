import React from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  Table,
  Tbody,
  Tr,
  Td,
  Input,
  VStack,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";

const PolicySection = ({ title, children }) => (
  <Box mt={6}>
    <Heading as="h2" size="md" mb={4}>
      {title}
    </Heading>
    {children}
  </Box>
);

const TableRow = ({ label }) => (
  <Tr>
    <Td borderColor="white" width="50%">
      {label}
    </Td>
    <Td borderColor="white" width="50px">
      :
    </Td>
    <Td borderColor="white">
      <Input
        variant="flushed"
        placeholder=" "
        _placeholder={{ opacity: 1, color: "gray.500" }}
        borderColor="white"
      />
    </Td>
  </Tr>
);

const TableRowSecond = ({ label, description }) => (
  <Tr>
    <Td borderColor="white" p={4}>
      <VStack align="start" spacing={2}>
        <Checkbox colorScheme="blue">
          <Text>{label}</Text>
        </Checkbox>
        {description && (
          <Text fontSize="sm" color="gray.400" pl={6}>
            {description}
          </Text>
        )}
      </VStack>
    </Td>
  </Tr>
);

const VeriSahibiBasvuruFormu = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        YENKİDO BİLİŞİM HİZMETLERİ TİCARET ANONİM ŞİRKETİ VERİ SAHİBİ BAŞVURU
        FORMU
      </Heading>

      <PolicySection>
        <Text>
          İşbu Veri Sahibi Başvuru Formu'nu ("Başvuru Formu" ya da "Form"), veri
          sahipleri tarafından 6698 sayılı Kişisel Verilerin Korunması
          Kanunu'nun ("Kanun") 11. maddesi çerçevesinde yapılacak başvuruları
          değerlendirebilmek amacıyla Yenkido Bilişim Hizmetleri Ticaret Anonim
          Şirketi ("Norbi") oluşturmuştur.
        </Text>
      </PolicySection>

      <PolicySection title="I. Başvuru Yöntemi">
        <Text>
          Kanun'un 11. maddesinde belirtilen haklarınızdan herhangi birini
          kullanmak istemeniz halinde, işbu Başvuru Formu'nu doldurmalı ve III
          numaralı başlık altında belirtilen ekleri ile birlikte, Kanun'un 13.
          maddesi ile Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
          Tebliğ'in ("Tebliğ") 5. maddesine uygun olarak,
        </Text>
        <List mt={4} spacing={2} ml={4}>
          <ListItem>
            I. Form'un ıslak imzalı bir nüshasını, Halaskargazi Cad. No: 38-66E
            İç Kapı No: 215, Nişantaşı Lotus, 34371 Şişli/İstanbul adresine
            şahsen başvuru ile iletmeli veya
          </ListItem>
          <ListItem>
            II. Form'un ıslak imzalı bir nüshasını Halaskargazi Cad. No: 38-66E
            İç Kapı No: 215, Nişantaşı Lotus, 34371 Şişli/İstanbul adresine
            noter yoluyla tebliğ etmeli veya
          </ListItem>
          <ListItem>
            III. Formu{" "}
            <Link href="mailto:yenkidobilisim@hs01.kep.tr">
              yenkidobilisim@hs01.kep.tr
            </Link>{" "}
            kayıtlı elektronik posta adresine kayıtlı elektronik posta (KEP) ile
            iletmeli veya
          </ListItem>
          <ListItem>
            IV. <Link href="mailto:destek@norbiapp.com">destek@norbiapp.com</Link>{" "}
            e-posta adresine güvenli elektronik imza, mobil imza ya da daha önce
            tarafımıza bildirdiğiniz ve sistemimizde kayıtlı bulunan elektronik
            posta adresinizi kullanarak göndermelisiniz.
          </ListItem>
        </List>
      </PolicySection>

      <PolicySection title="II. Veri Sahibine İlişkin Bilgiler">
        <Text>
          Lütfen aşağıda talep edilen bilgi ve belgeleri tam ve doğru şekilde
          sağlayınız. Bu bilgilerin, Tebliğ uyarınca tarafınızla iletişime
          geçebilmek ve kimliğin doğrulayabilmek adına başvurunuzda eksiksiz
          şekilde yer alması gerekmektedir.
        </Text>

        <Table variant="simple" color="white">
          <Tbody>
            <TableRow label="Ad ve Soyad" />
            <TableRow label="T.C. Kimlik Numarası" />
            <TableRow label="Yabancılar için Pasaport/Kimlik Numarası" />
            <TableRow label="Yabancılar için Uyruk" />
            <TableRow label="Tebligata Esas Yerleşim Yeri ve İş Yeri Adresi" />
            <TableRow label="Telefon Numarası (varsa)" />
            <TableRow label="E-posta Adresi (varsa)" />
            <TableRow label="Faks Numarası (varsa)" />
          </Tbody>
        </Table>
        <Text mt={4}>
          Lütfen Norbi ile olan ilişkiniz hakkında aşağıda belirtilenlerden
          uygun olan seçeneği seçiniz ve aşağıdaki boşluğa ilişkinin hala devam
          edip etmediği, sona ermiş ise hangi dönemlerde devam ettiği ve varsa
          Norbi ile olan ilişkiniz kapsamında irtibat halinde olduğunuz yetkili
          bilgisini yazınız.
        </Text>
        <Table variant="simple" color="white">
          <Tbody>
            <TableRow label="Müşteri" />
            <TableRow label="Çalışan" />
            <TableRow label="Tedarikçi/İş Ortağı/Alt Yüklenici" />
            <TableRow label="Ortak" />
            <TableRow label="Potansiyel Müşteri" />
            <TableRow label="İş Başvurusunda Bulunan" />
            <TableRow label="Ziyaretçi" />
            <TableRow label="Diğer (....................................)" />
            <Text mt={4}>Açıklamalar:</Text>
            <Textarea
              mt={2}
              w="100%"
              placeholder="Açıklamalarınızı buraya yazınız..."
            />
          </Tbody>
        </Table>
      </PolicySection>

      <PolicySection title="III. Veri Sahibi Talepleri">
        <Text>
          Kişisel verilere ilişkin taleplerinizi aşağıda ilgili kutucuğu (X) ile
          işaretleyerek belirtiniz.
        </Text>
        <Table variant="simple" color="white">
          <Tbody>
            <TableRowSecond
              label="Kişisel verilerimin Norbi tarafından işlenip işlenmediğini öğrenmek istiyorum."
              description="İşlendiğini düşündüğünüz belirli bir veri varsa, lütfen bu tablo sonunda bırakılan boşlukta belirtiniz."
            />
            <TableRowSecond
              label="Norbi tarafından kişisel verilerim işlenmişse buna ilişkin bilgi talep ediyorum."
              description="Bilgi talep ettiğiniz belirli bir veri varsa, bu tablo sonunda bırakılan boşlukta belirtiniz."
            />
            <TableRowSecond
              label="Norbi tarafından kişisel verilerimin işlenmesi durumunda kişisel verilerimin işlenme amacını ve bunların Norbi tarafından amacına uygun kullanılıp kullanılmadığını öğrenmek istiyorum."
              description="Talebinize konu belirli bir veri varsa, lütfen bu tablo sonunda bırakılan boşlukta belirtiniz."
            />
            <TableRowSecond label="Norbi tarafından kişisel verilerimin yurt içinde/yurt dışında üçüncü kişilere aktarılması durumunda Norbi tarafından kişisel verilerimin aktarıldığı üçüncü kişileri öğrenmek istiyorum." />
            <TableRowSecond
              label="Norbi tarafından eksik veya yanlış işlenen kişisel verilerimin düzeltilmesini istiyorum."
              description="Eksik veya yanlış işlendiğini düşündüğünüz kişisel verinizi ve nasıl düzeltilmesini istediğinizi bu tablo sonunda bırakılan boşlukta veya Form ekinde belirtiniz ve düzeltme talebinizi doğrulayan belgeleri işbu Form ekinde iletiniz."
            />
            <TableRowSecond
              label="Eksik/yanlış işlendiğini düşündüğüm kişisel verilerimin aktarıldığı üçüncü kişiler nezdinde de düzeltilmesini istiyorum."
              description="Eksik veya yanlış işlendiğini düşündüğünüz kişisel verinizi ve nasıl düzeltilmesini istediğinizi bu tablo sonunda bırakılan boşlukta veya Form ekinde belirtiniz ve düzeltme talebinizi doğrulayan belgeleri işbu Form ekinde iletiniz."
            />
            <TableRowSecond
              label="Kişisel verilerimin işlenmesini gerektiren sebeplerin ortadan kalkması nedeniyle kişisel verilerimin silinmesini/yok edilmesini istiyorum."
              description="İşlenme sebebinin neden ortadan kalktığını düşündüğünüzü ve silinmesini/yok edilmesini istediğiniz veriyi bu tablo sonunda bırakılan boşlukta veya Form ekinde belirtiniz ve varsa talebinizi tevsik edici diğer bilgi/belgeleri Form ekinde iletiniz."
            />
            <TableRowSecond
              label="Kişisel verilerimin işlenmesini gerektiren sebeplerin ortadan kalkması nedeniyle aktarıldıkları üçüncü kişiler nezdinde de silinmesini/yok edilmesini istiyorum."
              description="İşlenme sebebinin neden ortadan kalktığını düşündüğünüzü ve silinmesini/yok edilmesini istediğiniz veriyi bu tablo sonunda bırakılan boşlukta veya Form ekinde belirtiniz ve varsa talebinizi tevsik edici diğer bilgi/belgeleri Form ekinde iletiniz."
            />
            <TableRowSecond
              label="Norbi tarafından işlenen kişisel verilerimin münhasıran otomatik sistemler vasıtasıyla analiz edildiğini ve bu analiz neticesinde aleyhime bir sonuç doğduğunu düşünüyor ve bu sonuca itiraz ediyorum."
              description="Otomatik sistemler vasıtasıyla analiz edildiğini düşündüğünüz verilerinizi ve aleyhinize olduğunu düşündüğünüz sonucu bu tablo sonunda bırakılan boşlukta veya Form ekinde belirtiniz ve varsa talebinizi tevsik edici bilgi/belgeleri Form ekinde iletiniz."
            />
            <TableRowSecond
              label="Kişisel verilerimin Kanun'a aykırı işlenmesi nedeniyle uğradığım zararın tazminini talep ediyorum."
              description="Kişisel verilerinizin neden Kanun'a aykırı olarak işlendiği düşündüğünüzü ve Kanun'a aykırı işleme faaliyeti ve uğradığınız zarar ile birlikte bu tablo sonunda bırakılan boşlukta veya form ekinde belirtiniz."
            />
          </Tbody>
        </Table>
        <Text mt={4}>
          Başvurunuza sağlıklı yanıt verilebilmesi için başvuru kapsamının
          belirli, açık ve anlaşılabilir olması gerekmektedir. Lütfen yukarıda
          belirttiğiniz taleplerinize dair istenen bilgileri ve Form ekinde
          ilettiğiniz belgeleri aşağıda yer alan alanda belirtiniz.
        </Text>
        <Text mt={4}>
          ________________________________________________________________________________
        </Text>
        <Text>
          ________________________________________________________________________________
        </Text>
      </PolicySection>

      <PolicySection title="IV. Başvurunun Sonuçlandırılması ve Başvuru Sonucunun Bildirimi">
        <Text>
          Norbi, talebinizi, talebin niteliğine göre en geç 30 (otuz) gün
          içerisinde değerlendirerek sonuçlandıracaktır. Talebinize yönelik
          cevaplar yazılı olarak veya elektronik ortamda bildirilebilir. Başvuru
          sonucunun tarafınıza iletilmesine yönelik bir tercihiniz varsa, bu
          tercihinizi aşağıda belirtmeniz gerekmektedir. Herhangi bir cevap
          yöntemi tercih edilmemesi halinde başvuru iletildiği usul ile
          cevaplandırılacaktır.
        </Text>
        <Table variant="simple" color="white">
          <Tbody>
            <TableRowSecond label="Başvuruma ilişkin cevabın e-posta adresime gönderilmesini istiyorum." />
            <TableRowSecond label="Başvuruma ilişkin cevabın adresime gönderilmesini istiyorum." />
            <TableRowSecond
              label="Başvuruma ilişkin cevabı elden teslim almak istiyorum."
              description="(Vekaleten teslim alınması halinde teslim alacak kişinin, işbu konu özelinde yetkilendirildiği gösteren Noter'de düzenlenmiş vekaletnamesi gerekmektedir.)"
            />
          </Tbody>
        </Table>

        <Text mt={4}>
          Talepleriniz kural olarak ücretsiz sonuçlandırılacak olmakla birlikte,
          talebinizin cevaplandırılmasının ayrıca bir maliyeti gerektirmesi
          hâlinde, Kişisel Verileri Koruma Kurulu tarafından belirlenecek
          tarifedeki ücret talep edilebilecektir.
        </Text>
        <Text mt={4}>
          Norbi, talebin sonuçlandırılması için ek bilgiye ihtiyaç duyması veya
          başvurunun veri sahibi tarafından yapıldığının tevsik edilememesi
          durumunda, sizinle işbu Form'da belirtilen iletişim bilgileriniz
          aracılığıyla iletişime geçilebilecektir. Form kapsamında iletmekte
          olduğunuz taleplerinize ilişkin bilgilerin doğru ve güncel olmaması,
          yanlış/yanıltıcı bilgilerle ya da yetkisiz başvuru yapılması halinde
          başvurunuz reddedilecektir. Norbi'nin usulsüz işlem yapan kişi
          hakkında yasal yollara başvurma hakkı saklıdır.
        </Text>
        <Text mt={4}>Veri Sahibinin Adı Soyadı: __</Text>
        <Text mt={4}>Başvuru Tarihi: __</Text>
        <Text mt={4}>İmza: __</Text>
      </PolicySection>
    </Box>
  );
};

export default VeriSahibiBasvuruFormu;
