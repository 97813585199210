import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Flex,
  Text,
  Button,
  Image,
  useBreakpointValue,
  Container,
  Link,
} from "@chakra-ui/react";
import "../styles/advantage.css";

import { System } from "polyverse-sdk-dev/dist/api/system";
import { motion } from "framer-motion";
import LoadingDots from "../components/LoadingDots";
const MotionImage = motion(Image);

const StyledText = ({
  children,
  color,
  fontSize = ["24px", "30px", "24px", "28px", "30px"],
  fontWeight,
  mb,
}) => (
  <Text fontSize={fontSize} fontWeight={fontWeight} color={color} mb={mb}>
    {children}
  </Text>
);

const NetworkIcon = ({ network, index, column }) => (
  <MotionImage
    key={network.id || index}
    src={network.icon || `/placeholder-icon-${index}.png`}
    alt={network.name || `Network ${index + 1}`}
    boxSize={{ base: "25px", sm: "35px", md: "40px" }}
    objectFit="contain"
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    mb={8}
    alignItems="center"
  />
);

const BlurryCircle = () => (
  <Box
    position="absolute"
    bottom="0"
    left="50%"
    transform="translate(-50%, 70%)"
    width="100vw"
    height="100vw"
    maxWidth="500px"
    maxHeight="500px"
    borderRadius="50%"
    background="rgba(120, 37, 237, 0.2)"
    filter="blur(150px)"
    zIndex="0"
  />
);

function Advantage({ arrayCount, loading }) {
  const columns = useBreakpointValue({ base: 3, sm: 3, md: 3, lg: 4, xl: 6 });

  const [networks, setNetworks] = useState([]);
  const [networkCount, setNetworkCount] = useState(0);

  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const fetchedNetworks = await System.networks();
        setNetworks(fetchedNetworks);

        const mainNetCount = fetchedNetworks.filter(
          (network) => !network.isTestNet
        ).length;

        setNetworkCount(mainNetCount);
      } catch (error) {
        console.error("Failed to fetch networks:", error);
      }
    };
    fetchNetworks();
  }, []);

  return (
    <Box
      pb={{ base: 28, sm: 44 }}
      mt={20}
      color="white"
      px={{ base: "5%", lg: 32 }}
      position="relative"
    >
      <Container maxW="container.xl">
        <VStack spacing={12} align="stretch">
          <Box textAlign="center">
            <StyledText
              fontSize={{
                base: "24px",
                sm: "50px",
                md: "40px",
                lg: "50px",
              }}
              fontWeight="bold"
              mb={4}
              sx={{ WebkitFontSmoothing: "antialiased" }}
              color={"rgb(236, 236, 236)"}
            >
              Norbi ile Keyifler Yerinde!
            </StyledText>
            {loading ? (
              <LoadingDots />
            ) : (
              <StyledText
                color="#888"
                fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
              >
                Norbi, güvenliği ve gizliliği, kullanım rahatlığı ile kusursuz
                bir şekilde harmanlayarak sunuyor. Esnekliği, yeteneği ve
                yeniliği eşsiz deneyimde birleştirerek, {arrayCount} token çifti
                ve 500'den fazla NFT koleksiyonunu tek bir uygulamada
                zahmetsizce yönetin.
              </StyledText>
            )}
          </Box>

          <Flex
            bg="#010101"
            borderRadius="15px"
            border="1px solid #333333"
            maxH={{ base: "350px", sm: "230px", md: "350px" }}
            direction="row"
            position="relative"
            overflow="hidden"
          >
            <Box
              px={{ base: 8, sm: 8, md: 16 }}
              py={{ base: 8, sm: 8, md: 12 }}
              mt={{ base: 8, sm: 0 }}
              mb={{ base: 8, sm: 0 }}
              height="100%"
              justifyContent="space-between"
            >
              <VStack alignItems="flex-start" spacing={4}>
                {[`${networkCount} Ağ`, `${arrayCount} Token Çifti`].map(
                  (text, index) => (
                    <Box
                      key={index}
                      color="#9256E7"
                      fontWeight="bold"
                      fontSize={{ base: "xl", md: "3xl", lg: "4xl" }}
                      lineHeight="1.2"
                      textAlign="stretch"
                    >
                      {loading ? (
                        <LoadingDots />
                      ) : (
                        text.split(" ").map((word, i) => (
                          <Text
                            as="span"
                            key={i}
                            color={i === 0 ? "#9256E7" : "white"}
                          >
                            {word}{" "}
                          </Text>
                        ))
                      )}
                    </Box>
                  )
                )}
                <Link href="https://web.norbi.app" isExternal>
                  <Button
                    variant="pvGradient"
                    width={{ base: "auto", md: "180px" }}
                  >
                    Keşfedin
                  </Button>
                </Link>
              </VStack>
            </Box>
            <Box flex="1" display="flex" overflow="hidden" position="relative">
              {[...Array(columns)].map((_, colIndex) => (
                <Box
                  alignItems="center"
                  key={colIndex}
                  className={`column ${
                    colIndex % 2 === 0 ? "scrollDown" : "scrollUp"
                  }`}
                  flex="1"
                  height="200%"
                  position="relative"
                  style={{
                    animation: `${
                      colIndex % 2 === 0 ? "scrollDown" : "scrollUp"
                    } 40s linear infinite`,
                  }}
                >
                  {[...networks, ...networks].map((network, index) => (
                    <NetworkIcon key={index} network={network} index={index} />
                  ))}
                </Box>
              ))}
            </Box>
          </Flex>
        </VStack>
      </Container>
      <BlurryCircle />
    </Box>
  );
}

export default Advantage;
