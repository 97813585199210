import React from "react";

import { Box, Heading, Text } from "@chakra-ui/react";

function Security() {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Güvenlik
      </Heading>

      <Text mt={4}>
        Kişiye özel tüm bilgi ve belgeler şifreli olarak çoklu güvenlik katmanlı
        sunucularda muhafaza edilir. Varlıklarınızı yüksek güvenlikli kripto
        cüzdanlarında birebir saklar. Kripto varlıklarınızın büyük bir bölümü
        çevrimdışı çoklu imzalı soğuk cüzdanlarda güvenle muhafaza edilir. 2
        Aşamalı Doğrulamaya (2FA) ek olarak, kripto çekme PIN kodu ve oltalama
        (phishing) kodu ile birden fazla güvenlik katmanı sayesinde
        kullanıcılarımızın güvenliği en üst seviyede sağlanır.<br/><br/>
        Sık al-sat yapmadığınız varklıklarınızı "Güvenli Cüzdan" ismini verdiğimiz dahili <b>merkeziyetsiz cüzdanda</b> güvenle saklayabilirsiniz. 
        MPC teknolojisi ile korunan cüzdanınızın özel anahtarları, cüzdanınızı oluştururken cihazınızda şifrelenir ve üç parçaya bölünerek farklı sunucularda saklanır. Bu sayede cüzdanınıza sadece siz erişebilirsiniz.
      </Text>
    </Box>
  );
}

export default Security;
