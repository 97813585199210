  export const formatPrice = (
    price,
    format,
    showVolumeAsMillionText = false,
    useThousandSeparator = true
  ) => {
    if (price === undefined || price === null) {
      return "";
    }
  
    if (format === "price") {
      let [integerPart, decimalPart] = parseFloat(price).toString().split(".");
      if (useThousandSeparator) {
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    }
  
    if (format === "priceFiat") {
      let formattedPrice = parseFloat(price).toFixed(2);
      let [integerPart, decimalPart] = formattedPrice.split(".");
      if (useThousandSeparator) {
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return `${integerPart}.${decimalPart}`;
    }
  
    if (format === "quantity") {
      let [integerPart, decimalPart] = parseFloat(price).toString().split(".");
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    }
  
    if (format === "volume") {
      if (showVolumeAsMillionText && price >= 1000000) {
        return `${(price / 1000000).toFixed(2)}Mn`;
      } else {
        let formattedPrice = parseFloat(price).toFixed(2);
        let [integerPart, decimalPart] = formattedPrice.split(".");
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
      }
    }
  
    if (format === "percent") {
      let formattedPrice = parseFloat(price).toFixed(2);
      return `%${formattedPrice}`;
    }
  };
  