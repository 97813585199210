import { Box, Heading, Text } from "@chakra-ui/react";

const LimitsAndCommissions = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Limit
      </Heading>

      <Text mt={4}>
        Polyverse platformunda, Kimlik Doğrulama (KYC) işlemlerini tamamlamış
        onaylı hesaplar için kripto varlık işlemlerinde herhangi bir üst limit
        bulunmamaktadır. Ancak, bu hesaplarda para yatırma ve çekme işlemleri
        için günlük üst limit 1.000.000 Türk Lirası olarak belirlenmiştir.
        Ayrıca, 30 günlük süreçte toplamda yapılacak para yatırma ve çekme
        işlemleri için belirlenen üst limit ise 5.000.000 Türk Lirasıdır. Bu,
        kullanıcıların belirtilen limitler dahilinde herhangi bir sınırlama
        olmaksızın işlem yapabilecekleri anlamına gelir.
      </Text>
    </Box>
  );
};

export default LimitsAndCommissions;
