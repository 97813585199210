import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Center,
  useBreakpointValue,
  Text,
  VStack,
  Input,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FaCoins, FaFireAlt } from "react-icons/fa";
import { BiSolidUpArrowSquare, BiSolidDownArrowSquare } from "react-icons/bi";

import { formatPrice } from "../Utils";
import { Trade } from "polyverse-sdk-dev/dist/api/trade";

function Prices() {
  const [loading, setLoading] = useState(true);
  const [tokens, setTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [arrayCount, setArrayCount] = useState(197);
  const [priceColors, setPriceColors] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("all");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const initialColor = "white";

  const StyledText = ({
    children,
    color = "white",
    sizes = ["24px", "30px", "24px", "28px", "30px"],
    mb,
  }) => (
    <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
      {children}
    </Text>
  );

  useEffect(() => {
    const fetchTokenCount = async () => {
      try {
        const response = await Trade.spot.symbols("TRY");
        setArrayCount(response.length);

        const filteredSymbols = response
          .slice()
          .sort((a, b) => b.price.count - a.price.count);

        setTokens(filteredSymbols);
        setFilteredTokens(filteredSymbols);
        connectWebSocket(response);
      } catch (error) {
        console.error("Failed to fetch tokens:", error);
      } finally {
        setLoading(false);
      }
    };

    const connectWebSocket = (symbols) => {
      const ws = new WebSocket(
        "wss://stream.binance.com:9443/ws/!miniTicker@arr@3000ms"
      );

      ws.onmessage = (event) => {
        const messages = JSON.parse(event.data);
        messages.forEach((message) => {
          updateTokenPrice(message);
        });
      };

      ws.onclose = () => {
        console.log("WebSocket closed. Reconnecting...");
        setTimeout(() => connectWebSocket(symbols), 5000);
      };
    };

    const updateTokenPrice = (message) => {
      setTokens((prevTokens) =>
        prevTokens.map((token) => {
          if (token.ticker === message.s) {
            const oldPrice = token.price.lastPrice;
            const newPrice = message.c;
            const color =
              newPrice > oldPrice
                ? "#66bb6a"
                : newPrice < oldPrice
                ? "#d32f2f"
                : initialColor;

            setPriceColors((prevColors) => ({
              ...prevColors,
              [token.ticker]: color,
            }));

            setTimeout(() => {
              setPriceColors((prevColors) => ({
                ...prevColors,
                [token.ticker]: initialColor,
              }));
            }, 2000);

            return {
              ...token,
              price: {
                ...token.price,
                lastPrice: newPrice,
                priceChangePercent: ((newPrice - message.o) / message.o) * 100,
                quoteVolume: message.q,
              },
            };
          }
          return token;
        })
      );
    };

    fetchTokenCount();
  }, []);

  useEffect(() => {
    const filtered = tokens.filter(
      (token) =>
        token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        token.ticker.toLowerCase().includes(searchQuery.toLowerCase())
    );

    let sortedFilteredTokens = [];

    if (filterType === "all") {
      sortedFilteredTokens = filtered.sort((a, b) => {
        if (a.baseAsset === "BTC" || a.baseAsset === "ETH") return -1;
        if (b.baseAsset === "BTC" || b.baseAsset === "ETH") return 1;
        return a.name.localeCompare(b.name);
      });
    } else if (filterType === "rising") {
      sortedFilteredTokens = filtered.sort(
        (a, b) => b.price.priceChangePercent - a.price.priceChangePercent
      );
    } else if (filterType === "falling") {
      sortedFilteredTokens = filtered.sort(
        (a, b) => a.price.priceChangePercent - b.price.priceChangePercent
      );
    } else if (filterType === "popular") {
      sortedFilteredTokens = filtered.sort(
        (a, b) => b.price.count - a.price.count
      );
    }

    setFilteredTokens(sortedFilteredTokens);
  }, [searchQuery, tokens, filterType]);

  return (
    <Box pt={{ base: 8, sm: 0 }}>
      {loading ? (
        <Center>
          <Box minH={"400px"} mt={40}>
            <Spinner color="#9156e7" size={"xl"} />
          </Box>
        </Center>
      ) : (
        <Box my={{ base: 12, md: 12, lg: 16 }} px={{ base: "5%", lg: 200 }}>
          <VStack spacing={4} align="stretch" maxW="1200px" mx="auto">
            <Box textAlign="center">
              <StyledText color="#9256E7" fontWeight="bold" mb={4}>
                Kripto Piyasasını Norbi ile Takip Et
              </StyledText>
              <Text
                fontSize={{ base: "24px", sm: "50px", md: "40px", lg: "50px" }}
                fontWeight="bold"
                sx={{ WebkitFontSmoothing: "antialiased" }}
                color={"rgb(236, 236, 236)"}
              >
                Kolay Al, Kolay Sat!
              </Text>
              <Text
                color="#888"
                fontSize={{ base: "16px", sm: "25px", md: "20px", lg: "25px" }}
              >
                Bitcoin ve diğer kripto varlıkları güvenli, hızlı ve kolay bir
                şekilde Türk Lirasıyla alın, satın ve yatırım yapın.{" "}
                {arrayCount}'den fazla paritede işlem yapma fırsatı sizleri
                bekliyor!
              </Text>
            </Box>
            <Input
              placeholder="Kripto Varlık Ara..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              mb={4}
            />
            <HStack spacing={1} overflowY={"auto"}>
              <Button
                leftIcon={<FaCoins />}
                onClick={() => setFilterType("all")}
                backgroundColor={filterType === "all" ? "#9156e7" : "#edf2f7"}
              >
                Tümü
              </Button>
              <Button
                leftIcon={<BiSolidUpArrowSquare />}
                onClick={() => setFilterType("rising")}
                backgroundColor={
                  filterType === "rising" ? "#9156e7" : "#edf2f7"
                }
              >
                Yükselen
              </Button>
              <Button
                leftIcon={<BiSolidDownArrowSquare />}
                onClick={() => setFilterType("falling")}
                backgroundColor={
                  filterType === "falling" ? "#9156e7" : "#edf2f7"
                }
              >
                Düşen
              </Button>
              <Button
                leftIcon={<FaFireAlt />}
                onClick={() => setFilterType("popular")}
                backgroundColor={
                  filterType === "popular" ? "#9156e7" : "#edf2f7"
                }
              >
                Popüler
              </Button>
            </HStack>
          </VStack>
          <Table variant="pvPriceTable" size="md" mt={8}>
            <Thead>
              <Tr>
                {!isMobile && <Th borderTopLeftRadius={12}>#</Th>}
                <Th borderTopLeftRadius={isMobile ? 12 : 0}>Token</Th>
                <Th borderTopRightRadius={isMobile ? 12 : 0}>Son Fiyat</Th>
                {!isMobile && <Th>24s Değişim</Th>}
                {!isMobile && <Th borderTopRightRadius={12}>24s Hacim</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {filteredTokens.map((token, tokenIndex) => (
                <Tr key={tokenIndex}>
                  {!isMobile && <Td>{tokenIndex + 1}</Td>}
                  <Td>
                    <Box display="flex" alignItems="center">
                      <img
                        src={token.logoUrl}
                        alt={token.name}
                        width="28"
                        height="28"
                      />
                      <Box ml="2">
                        <Box>{token.name}</Box>
                        <Box style={{ fontSize: "0.75em", color: "gray" }}>
                          {token.baseAsset}
                        </Box>
                      </Box>
                    </Box>
                  </Td>
                  <Td>
                    <Box>
                      <Text style={{ color: priceColors[token.ticker] }}>
                        ₺
                        {formatPrice(
                          token.price.lastPrice,
                          "price",
                          false,
                          true
                        )}
                      </Text>
                      {isMobile && (
                        <Box mt={1}>
                          <Text
                            color={
                              token.price.priceChangePercent < 0
                                ? "#d32f2f"
                                : "#66bb6a"
                            }
                          >
                            {formatPrice(
                              token.price.priceChangePercent,
                              "percent",
                              false,
                              false
                            ).replace("-", "")}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Td>
                  {!isMobile && (
                    <Td>
                      <Text
                        color={
                          token.price.priceChangePercent < 0
                            ? "#d32f2f"
                            : "#66bb6a"
                        }
                      >
                        {formatPrice(
                          token.price.priceChangePercent,
                          "percent",
                          false,
                          false
                        ).replace("-", "")}
                      </Text>
                    </Td>
                  )}
                  {!isMobile && (
                    <Td>
                      ₺
                      {formatPrice(
                        token.price.quoteVolume,
                        "volume",
                        true,
                        true
                      )}
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
}

export default Prices;
