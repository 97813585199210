import { Box, Heading, Text, List, ListItem } from "@chakra-ui/react";

const LimitsAndCommissions = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={16} textAlign="center">
        Limitler ve Komisyonlar
      </Heading>

      <Heading as="h2" size="md" mt={6}>
        Komisyonlar ve Ücretlendirme
      </Heading>
    <List mt={4} spacing={4}>
        <ListItem>
          Polyverse platformunda kullanıcılar, transfer, NFT işlemleri ve para
          yatırma/çekme işlemleri sırasında herhangi bir komisyon ödemezler. Bu,
          kullanıcıların bu temel işlemleri tamamen ücretsiz bir şekilde
          gerçekleştirebilecekleri anlamına gelir.
        </ListItem>
        <ListItem>
          Polyverse, platformun sürekli gelişimini ve iyileştirilmesini
          desteklemek amacıyla, swap işlemlerine otomatik olarak %0,875 hizmet
          ücreti ekler. Bu küçük ücret, her swap teklifine dahil edilerek,
          platformun devamlı gelişimi için kaynak oluşturur.
        </ListItem>
        <ListItem>
          Alım ve satım işlemleri için, Polyverse her işlemde %1'lik bir ücret
          alır. Bu ücret, işlem yapılan toplam tutarın bir yüzdesi olarak
          hesaplanır ve dünya genelinde tüm bölgelerde, ödeme yöntemleri,
          sağlayıcılar ve farklı token türleri için geçerlidir.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6}>
        Limitler
      </Heading>
      <Text mt={4}>
        Polyverse platformunda, Kimlik Doğrulama (KYC) işlemlerini tamamlamış
        onaylı hesaplar için kripto varlık işlemlerinde herhangi bir üst limit
        bulunmamaktadır. Ancak, bu hesaplarda para yatırma ve çekme işlemleri
        için günlük üst limit 1.000.000 Türk Lirası olarak belirlenmiştir.
        Ayrıca, 30 günlük süreçte toplamda yapılacak para yatırma ve çekme
        işlemleri için belirlenen üst limit ise 5.000.000 Türk Lirasıdır. Bu,
        kullanıcıların belirtilen limitler dahilinde herhangi bir sınırlama
        olmaksızın işlem yapabilecekleri anlamına gelir.
      </Text>
    </Box>
  );
};

export default LimitsAndCommissions;