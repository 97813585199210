import React from "react";
import {
  Box,
  Container,
  Text,
  Button,
  VStack,
  Image,
  Flex,
  Link,
  useColorModeValue,
  keyframes,
  Heading,
} from "@chakra-ui/react";

import KazanText from "../assets/kazan-text-arrow.svg";
import KolayText from "../assets/kolay-text-arrow.svg";

// Define keyframes for the animation
const gradientAnimation = keyframes`
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -200%;
  }
`;

// Define the gradient background style with animation
const gradientBackground = {
  background:
    "linear-gradient(90deg, white 25%, #B283F4 30%, rgba(255, 255, 255, 1) 70%, #B283F4 75%, white 100%)",
  backgroundSize: "200% auto",
  animation: `${gradientAnimation} 12s linear infinite`,
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  WebkitFontSmoothing: "antialiased",
};

const BlurryCircle = () => (
  <Box
    position="absolute"
    top="20%"
    left="50%"
    transform="translate(-50%, -50%)"
    width="100vw"
    height="100vw"
    maxWidth="500px"
    maxHeight="500px"
    borderRadius="50%"
    background="rgba(120, 37, 237, 0.2)"
    filter="blur(150px)"
    zIndex="0"
  />
);

const GradientBorderFrame = ({ children }) => {
  return (
    <Box
      position="relative"
      height={{ base: "260px", md: "280px", lg: "365px" }}
      width={{ base: "100%", md: "100%", lg: "528px" }}
      mt={{ base: 24, lg: 32 }}
      mb={{ base: 12, lg: 12 }}
    >
      <Box
        position="relative"
        width="100%"
        height="100%"
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          padding: "2px",
          background: "linear-gradient(90deg, #529BF0 0%, #9256E7 100%)",
          WebkitMask:
            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          WebkitMaskComposite: "xor",
          maskComposite: "exclude",
          borderRadius: "12px",
        }}
      >
        <Box
          as="video"
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: "5px",
            left: "5px",
            width: "calc(100% - 10px)",
            height: "calc(100% - 10px)",
            backgroundColor: "black",
            borderRadius: "12px",
          }}
        >
          <source src="norbi.mp4" type="video/mp4" />
        </Box>
        {children}
        {/* Kolay Text */}

        <Image
          src={KolayText}
          position="absolute"
          top={{ base: "-70px", md: "-70px", lg: "-70px" }}
          right={{ base: "40px", md: "40", lg: "40" }}
          width={{ base: "250px", md: "250px", lg: "250px" }}
          height="auto"
        />

        {/* Kazan Text */}
        <Image
          src={KazanText}
          position="absolute"
          bottom={{ base: "-88px", md: "-96px", lg: "-88px" }}
          left={{ base: "24px", md: "32px", lg: "32px" }}
          width={{ base: "448px", md: "448px", lg: "400px" }}
          height="auto"
        />
      </Box>
    </Box>
  );
};

function WelcomeNorbi() {
  const textColor = useColorModeValue("white", "white");

  return (
    <Box
      as="section"
      color={textColor}
      height={"full"}
      px={{ base: "5%", md: 28, lg: 20, xl: 32 }}
      position="relative"
    >
      <BlurryCircle />

      <Container
        maxW="container.xl"
        height="100%"
        alignItems="start"
        position="relative"
      >
        <Flex direction={{ base: "column", lg: "row" }} justify="space-between">
          <VStack
            spacing={{ base: 12, sm: 14 }}
            alignItems="start"
            justifyContent="center"
          >
            <VStack align="start">
              <Heading
                as="h2"
                size="lg"
                color={"#b2b2b2"}
                sx={{ WebkitFontSmoothing: "antialiased" }}
              >
                Finansal Özgürlüğe Giden Yolda
              </Heading>
              <Heading
                as="h2"
                size="2xl"
                sx={gradientBackground}
                color={"rgb(236, 236, 236)"}
              >
                Kripto Alım, Satım ve Cüzdan
              </Heading>
            </VStack>
            <Link href="https://web.norbi.app" isExternal>
              <Button
                variant="pvGradient"
                width={{ base: "100%", md: "100%", lg: "360px" }}
                p={4}
                mt={-8}
              >
                Geleceğin Finansı Burada!
              </Button>
            </Link>
          </VStack>

          <GradientBorderFrame></GradientBorderFrame>
        </Flex>
      </Container>
    </Box>
  );
}

export default WelcomeNorbi;
