import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Image,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useOutsideClick,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import NorbiLogo from "../assets/logo/norbi_logo_white.png";
import { useTranslation } from "react-i18next";
import AppStore from "../assets/icons/appstore-download.svg";
import GooglePlay from "../assets/icons/playstore-download.svg";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";

// Utility function to detect device type
const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "Other";
};

const Header = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const popoverRef = useRef();

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const btnRef = useRef();

  const [appUrl, setAppUrl] = useState("");
  const [showQR, setShowQR] = useState(false);
  const [deviceType, setDeviceType] = useState("Other");

  useOutsideClick({
    ref: popoverRef,
    handler: () => onClose(),
  });

  useEffect(() => {
    setDeviceType(getDeviceType());
  }, []);

  const handleMouseEnter = (url) => {
    setAppUrl(url);
    setShowQR(true);
  };

  const handleMouseLeave = () => {
    setShowQR(false);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const changeLanguage = (lang) => {
    if (typeof i18n.changeLanguage === "function") {
      i18n.changeLanguage(lang);
    } else {
      console.error("changeLanguage method is not available on i18n object");
    }
  };

  const togglePopover = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleAppClick = (url) => {
    window.location.href = url;
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as="header"
      py={{ base: 4, sm: 6 }}
      position="fixed"
      top={0}
      left={0}
      right={0}
      width="100%"
      zIndex="sticky"
      bg="rgb(1, 1, 1, 0.5)"
      backdropFilter="blur(16px)"
      px={{ base: "5%", md: 28, lg: 20, xl: 32 }}
    >
      <Flex justify="space-between">
        <Box
          mr={5}
          onClick={handleLogoClick}
          cursor="pointer"
          alignContent={{ base: "start", sm: "center" }}
        >
          <Image
            src={NorbiLogo}
            w={{ base: "100px", sm: "100px", md: "110px" }}
          />
        </Box>
        <Flex align="center">
          {isMobile ? (
            <>
              <IconButton
                ref={btnRef}
                icon={<HamburgerIcon />}
                onClick={onDrawerOpen}
                aria-label="Open Menu"
                bgColor={"#111"}
                border={"1px solid #281e5a"}
                color={"#e97351"}
              />
              <Drawer
                isOpen={isDrawerOpen}
                placement="right"
                onClose={onDrawerClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay>
                  <DrawerContent bg="#010101" color="white">
                    <DrawerCloseButton />
                    <DrawerBody mt={10}>
                      <Link href="https://web.norbi.app" isExternal>
                        <Button variant="pvGoWallet" w="100%" mb={2}>
                          Cüzdan
                        </Button>
                      </Link>
                      <Button
                        variant="pvGoWallet"
                        w="100%"
                        mb={2}
                        onClick={() => navigate("/prices")}
                      >
                        Piyasalar
                      </Button>
                      <Popover
                        placement="bottom-end"
                        isOpen={isOpen}
                        onClose={onClose}
                        closeOnBlur={false}
                      >
                        <PopoverTrigger>
                          <Button
                            variant="pvGradient"
                            width="100%"
                            height="45px"
                            fontSize={{
                              base: "16px",
                              md: "16px",
                              lg: "18px",
                              xl: "20px",
                            }}
                            onClick={togglePopover}
                          >
                            İndir
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent
                          ref={popoverRef}
                          bg="#111"
                          border="2px solid #222"
                          width="auto"
                          px="0"
                          transformOrigin="right"
                          _focus={{ boxShadow: "none" }}
                        >
                          <PopoverBody py="4">
                            <Flex direction="row" gap={4} width="auto">
                              {showQR && (
                                <div
                                  style={{
                                    width: "auto",
                                    transition: "width 0.5s ease-in-out",
                                    borderRadius: "6px",
                                    overflow: "hidden",
                                    padding: "6px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <QRCode
                                    value={appUrl}
                                    size={90}
                                    level={"H"}
                                    includeMargin={false}
                                  />
                                </div>
                              )}
                              <Flex direction="column" gap={4}>
                                <Button
                                  px="0"
                                  bg="transparent"
                                  _hover={{}}
                                  _active={{}}
                                  onMouseEnter={() =>
                                    handleMouseEnter(
                                      "https://apps.apple.com/tr/app/norbi-kripto-al-sat-c%C3%BCzdan/id6505041488"
                                    )
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() =>
                                    handleAppClick(
                                      "https://apps.apple.com/tr/app/norbi-kripto-al-sat-c%C3%BCzdan/id6505041488"
                                    )
                                  }
                                >
                                  <Image
                                    src={AppStore}
                                    alt="App Store"
                                    width="40"
                                  />
                                </Button>
                                <Button
                                  px="0"
                                  bg="transparent"
                                  _hover={{}}
                                  _active={{}}
                                  onMouseEnter={() =>
                                    handleMouseEnter(
                                      "https://play.google.com/store/apps/details?id=app.norbi.mobile&pcampaignid=web_share"
                                    )
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() =>
                                    handleAppClick(
                                      "https://play.google.com/store/apps/details?id=app.norbi.mobile&pcampaignid=web_share"
                                    )
                                  }
                                >
                                  <Image
                                    src={GooglePlay}
                                    alt="Google Play"
                                    width="40"
                                  />
                                </Button>
                              </Flex>
                            </Flex>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </DrawerBody>
                  </DrawerContent>
                </DrawerOverlay>
              </Drawer>
            </>
          ) : (
            <Flex gap={1} wrap="wrap" align="center" justify="flex-end">
              <Link href="https://web.norbi.app" isExternal>
                <Button variant="pvGoWallet">Cüzdan</Button>
              </Link>
              <Button variant="pvGoWallet" onClick={() => navigate("/prices")}>
                Piyasalar
              </Button>
              <Popover
                placement="bottom-end"
                isOpen={isOpen}
                onClose={onClose}
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <Button
                    variant="pvGradient"
                    width="auto"
                    height="45px"
                    fontSize={{
                      base: "16px",
                      md: "16px",
                      lg: "18px",
                      xl: "20px",
                    }}
                    onClick={togglePopover}
                  >
                    İndir
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  ref={popoverRef}
                  bg="#111"
                  border="2px solid #222"
                  width="auto"
                  px="0"
                  transformOrigin="right"
                  _focus={{ boxShadow: "none" }}
                >
                  <PopoverBody py="4">
                    <Flex direction="row" gap={4} width="auto">
                      {showQR && (
                        <div
                          style={{
                            width: "auto",
                            transition: "width 0.5s ease-in-out",
                            borderRadius: "6px",
                            overflow: "hidden",
                            padding: "6px",
                            backgroundColor: "white",
                          }}
                        >
                          <QRCode
                            value={appUrl}
                            size={90}
                            level={"H"}
                            includeMargin={false}
                          />
                        </div>
                      )}
                      <Flex direction="column" gap={4}>
                        <Button
                          px="0"
                          bg="transparent"
                          _hover={{}}
                          _active={{}}
                          onMouseEnter={() =>
                            handleMouseEnter(
                              "https://apps.apple.com/tr/app/norbi-kripto-al-sat-c%C3%BCzdan/id6505041488"
                            )
                          }
                          onMouseLeave={handleMouseLeave}
                          onClick={() =>
                            handleAppClick(
                              "https://apps.apple.com/tr/app/norbi-kripto-al-sat-c%C3%BCzdan/id6505041488"
                            )
                          }
                        >
                          <Image src={AppStore} alt="App Store" width="40" />
                        </Button>
                        <Button
                          px="0"
                          bg="transparent"
                          _hover={{}}
                          _active={{}}
                          onMouseEnter={() =>
                            handleMouseEnter(
                              "https://play.google.com/store/apps/details?id=app.norbi.mobile&pcampaignid=web_share"
                            )
                          }
                          onMouseLeave={handleMouseLeave}
                          onClick={() =>
                            handleAppClick(
                              "https://play.google.com/store/apps/details?id=app.norbi.mobile&pcampaignid=web_share"
                            )
                          }
                        >
                          <Image
                            src={GooglePlay}
                            alt="Google Play"
                            width="40"
                          />
                        </Button>
                      </Flex>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
