import React from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  Link,
  Text,
  Divider,
  VStack,
  HStack,
  Image,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Logo from "../assets/logo/norbi_logo_white.png";
import Twitter from "../assets/icons/twitter.svg";
import LinkedIn from "../assets/icons/linkedin.svg";
import Medium from "../assets/icons/medium.svg";
import Instagram from "../assets/icons/instagram.svg";

const SocialButton = ({ icon, href }) => (
  <Link href={href} isExternal>
    <Box
      h={{ base: "30px", sm: "35px", md: "40px" }}
      w={{ base: "30px", sm: "35px", md: "40px" }}
      borderRadius="full"
      bg="rgba(51, 51, 51, 0.5)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _hover={{bg:"#333"}}
      _active={{bg:"#888"}}
    >
      <Image src={icon} alt="Social Icon" />
    </Box>
  </Link>
);

const footerSections = [
  {
    title: "Yardım",
    items: [
      { name: "Destek", link: "https://destek.norbi.app/tr/" },
      { name: "Komisyonlar", link: "/komisyonlar" },
      { name: "Limit", link: "/limit" },
      { name: "Güvenlik", link: "/guvenlik" },
      // { name: "Norbi LightPaper", link: "/assets/lightpaper.pdf" },
    ],
  },
  {
    title: "Yasal",
    items: [
      { name: "AML&KYC", link: "/aml-kyc" },
      { name: "Kullanıcı Sözleşmesi", link: "/kullanici-sozlesmesi" },
      { name: "Risk Bildirimi", link: "/risk-bildirimi" },
      { name: "Çerez Politikası", link: "/cerez-politikasi" },
      {
        name: "KVKK Aydınlatma Metni",
        link: "/tr/legal?highlight=KVKK-Aydinlatma-Metni",
      },
      // {
      //   name: "Risk İştahı Bildirimi",
      //   link: "/risk-istahi-bildirimi",
      // },
      {
        name: "Rüşvet ve Yolsuzlukla Mücadele Politikası",
        link: "/rusvet-ve-yolsuzlukla-mucadele-politikasi",
      },
      {
        name: "Başkası Hesabına İşlem Yapanlara İlişkin Duyuru",
        link: "/baskasi-hesabina-islem-yapanlara-iliskin-duyuru",
      },
      {
        name: "Veri Başvuru Formu",
        link: "/veri-basvuru-formu",
      },
    ],
  },
  {
    title: "Kurumsal",
    items: [
      { name: "Hakkımızda", link: "/hakkimizda" },
      { name: "Ticaret Sicil Bilgileri", link: "/ticaret-sicil-bilgileri" },
      { name: "İletişim", link: "/iletisim" },
      { name: "Medya ve Basın", link: "/medya-ve-basin" },
    ],
  },
];

const Footer = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate(); // Use navigate function

  const handleNavigate = (link) => {
    if (link.startsWith("http")) {
      window.open(link, "_blank", "noopener,noreferrer");
    } else {
      navigate(link);
      window.scrollTo(0, 0); // Scroll to top after navigation
    }
  };

  return (
    <Box
      as="footer"
      bg="black"
      color="white"
      pb={{ base: 8, md: 12 }}
      pt={{ base: 8, md: 12 }}
      px={{ base: "5%", lg: 32 }}
      zIndex={1}
    >
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          mb={{ base: 8, md: 24 }}
        >
          <VStack
            align="flex-start"
            spacing={{ base: 6, md: 12 }}
            mb={{ base: 8, md: 0 }}
          >
            <Image
              src={Logo}
              alt="Norbi Logo"
              w={{ base: "150px", md: "200px" }}
            />
            {!isMobile && (
              <HStack
                spacing={{ md: "10px", lg: "15px" }}
                pl={{ md: "0", lg: "20px" }}
              >
                <SocialButton
                  icon={Twitter}
                  href="https://x.com/NorbiApp_TR"
                />
                <SocialButton
                  icon={LinkedIn}
                  href="https://www.linkedin.com/company/norbiapp/"
                />
                <SocialButton
                  icon={Medium}
                  href="https://medium.com/@norbiapp"
                />
                 <SocialButton
                  icon={Instagram}
                  href="https://www.instagram.com/norbiapp?igsh=MWdtNmhhbzYzdmF5Yw=="
                />
              </HStack>
            )}
          </VStack>
          {isMobile ? (
            <Accordion allowToggle>
              {footerSections.map((section) => (
                <AccordionItem key={section.title} border="none">
                  <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                      {section.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack align="flex-start" spacing={3}>
                      {section.items.map((item) => (
                        <Link
                          key={item.name}
                          href={item.link}
                          fontSize="sm"
                          color="rgba(255, 255, 255, 0.6)"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNavigate(item.link);
                          }}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(3, 1fr)" }}
              gap={{ base: 8, md: "10px", lg: "30px", xl: "50px" }}
              mt={{ base: 8, md: 16 }}
              mr={{ base: 12, md: 8, lg: 20 }}
              ml={{ base: 12, md: 8, lg: 20 }}
            >
              {footerSections.map((section) => (
                <VStack key={section.title} align="flex-start" spacing={4}>
                  <Text fontWeight="bold" fontSize={{ base: "md", md: "lg" }}>
                    {section.title}
                  </Text>
                  <VStack
                    align="flex-start"
                    spacing={{ base: 1, md: 2, lg: 3 }}
                  >
                    {section.items.map((item) => (
                      <Link
                        key={item.name}
                        href={item.link}
                        fontSize={{ base: "sm", md: "md" }}
                        color="rgba(255, 255, 255, 0.6)"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigate(item.link);
                        }}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </VStack>
                </VStack>
              ))}
            </Grid>
          )}

          {isMobile && (
            <HStack
              spacing={{ base: "8px", sm: "10px" }}
              mt={{ base: 8, md: 0 }}
            >
              <SocialButton
                icon={Twitter}
                href="https://x.com/NorbiApp_TR"
              />
              <SocialButton
                icon={LinkedIn}
                href="https://www.linkedin.com/company/norbiapp/"
              />
              <SocialButton icon={Medium} href="https://medium.com/@norbiapp" />
              <SocialButton
                  icon={Instagram}
                  href="https://www.instagram.com/norbiapp?igsh=MWdtNmhhbzYzdmF5Yw=="
                />
            </HStack>
          )}
        </Flex>
        <Divider mb="5" color="white" opacity={"0.15"} borderWidth={"2px"} />
        <VStack spacing={{ base: 0, sm: 2 }} align="flex-start">
          <Text fontSize={{ base: "xs", sm: "sm", md: "md" }}>
            Tüm Hakları Saklıdır © 2022 Yenkido Bilişim Hizmetleri Ticaret AŞ
            Mersis NO : 0949076075300001
          </Text>
          <Text
            fontSize={{ base: "2xs", sm: "xs", md: "sm" }}
            color="rgba(255, 255, 255, 0.4)"
          >
            Adres: Halaskargazi Cd. No: 38-66E İç Kapı No:215, Nişantaşı Lotus, 34371 Şişli-İstanbul
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
